import type { FC } from 'react';
import React from 'react';
import type { BillingSummaryItemProps } from './BillingSummaryItem.types';
import { covertTimestampToDate, getCardImage } from '~utils';

import { useScreenSize } from '~hooks';
import { icons } from '~constants';

import './BillingSummaryItem.styles.scss';
import { useTranslation } from 'react-i18next';

export const BillingItem: FC<BillingSummaryItemProps> = (props) => {
    const { data } = props;
    const { isMobile } = useScreenSize();

    const cost = data.amount_paid / 100;
    const date = covertTimestampToDate(data.created);
    const cardEnding = data.charge?.payment_method_details?.card?.last4;
    const cardIcon = getCardImage(
        data.charge?.payment_method_details?.card?.brand,
    );

    const { t } = useTranslation();

    const onViewClick = () => {
        window.open(data.hosted_invoice_url, '_blank');
    };

    return (
        <div className="billing-item">
            <div className="billing-item-content">
                <div className="plan-info">
                    <h2>
                        {t('unlimited_access')} - USD ${cost}
                    </h2>
                    <p>{date}</p>
                </div>
                <div className="payment-details">
                    <img src={cardIcon} alt="card" />
                    <p>
                        {t('ending_in')} {cardEnding}
                    </p>
                </div>
            </div>
            <button onClick={onViewClick}>
                {t('view')} <img src={icons.externalLink} alt="view" />
            </button>
        </div>
    );
};
