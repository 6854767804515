import React, { FC, useState } from 'react';

import SendIcon from '../../../assets/send-icon.svg';
import './SubmitQuesFormPopup.styles.scss';
// import { Assets } from '~constants';
// import { useNavigate } from 'react-router-dom';
// import { InputField } from '~components/InputField';
import {
    inputFieldStyles,
    textAreaFieldStyles,
} from '~components/UserInfo/EditUserInfo';
// import { t } from 'i18next';
import { useAuth } from '~hooks';
import { ValidationError, useForm } from '@formspree/react';

type Props = {
    open: boolean;
    step: number;
    onClose: () => void;
    onLeftButtonClick: () => void;
    onRightButtonClick: () => void;
    increaseStep: () => void;
};

export const SubmitQuesFormPopup: FC<Props> = (props) => {
    const {
        open,
        onClose,
        onRightButtonClick,
        onLeftButtonClick,
        // increaseStep,
    } = props;

    const { user } = useAuth();

    const [state, handleSubmit] = useForm('mqazklre');

    console.log('🚀 ~ state:', state);

    const firstName = user?.data?.attributes.given_name || '';
    const lastName = user?.data?.attributes.family_name || '';
    const email = user?.data?.attributes.email;
    const token = user?.data?.signInUserSession.idToken.jwtToken;
    const userData = { firstName, lastName, email, token };

    const userFullName = firstName + ' ' + lastName;

    const [inputs, setInputs] = useState({
        name: userFullName ? userFullName : '',
        email: email,
        message: '',
    });

    const handleOnChange = (event: any) => {
        setInputs((prev) => ({
            ...prev,
            [event.target.id]: event.target.value,
        }));
    };

    const handleOnSubmit = () => {
        // event.preventDefault();
        onRightButtonClick();
        if (state?.succeeded === true) {
            onClose();
            window.location.reload();
        }
    };

    const handleClose = () => {
        onClose();
    };

    if (!open) {
        return null;
    }

    return (
        <div className="form-popup">
            <form onSubmit={handleOnSubmit}>
                <div className="form-content">
                    <div className="form-items">
                        <div className="form-items__item">
                            <label className="msg-label" htmlFor="name">
                                Name
                            </label>
                            <input
                                id="name"
                                type="text"
                                name="name"
                                placeholder={'Your Name'}
                                onChange={handleOnChange}
                                value={inputs.name}
                                style={inputFieldStyles}
                                required
                            />
                            <ValidationError
                                prefix="name"
                                field="name"
                                errors={state.errors}
                            />
                        </div>

                        <div className="form-items__item">
                            <label className="msg-label" htmlFor="email">
                                Email
                            </label>
                            <input
                                id="email"
                                type="email"
                                name="email"
                                placeholder="example@email.com"
                                onChange={handleOnChange}
                                value={inputs.email}
                                style={inputFieldStyles}
                                disabled={true}
                            />
                            {/* <p>* {t('email_cannot_be_changed')}</p> */}
                        </div>

                        <div className="form-items__item">
                            <label className="msg-label" htmlFor="message">
                                Message
                            </label>
                            <textarea
                                id="message"
                                name="message"
                                placeholder={'Describe your issue here...'}
                                onChange={handleOnChange}
                                value={inputs.message}
                                style={textAreaFieldStyles}
                                required
                            />
                            <ValidationError
                                prefix="message"
                                field="message"
                                errors={state.errors}
                            />
                        </div>
                    </div>

                    <div className="buttons-container">
                        <button
                            onClick={onLeftButtonClick}
                            className="outlined"
                        >
                            Back
                        </button>
                        <button
                            disabled={state.submitting}
                            type="submit"
                            className="filled"
                        >
                            <img
                                className="send-icon"
                                src={SendIcon}
                                alt="send"
                            />{' '}
                            Send
                        </button>
                    </div>
                </div>
            </form>
            <div className="background-modal" onClick={handleClose}></div>
        </div>
    );
};
