import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    forgotPassword,
    forgotPasswordSubmit,
} from './ForgetPassword.functions';
import { useSnackbar } from '~hooks';
import { AppRoutes, defaultErrorMessage } from '~constants';
import { Header } from '~components/Header';
import { SubmitEmail } from './SubmitEmail';
import { SubmitPassword } from './SubmitPassword';

import './ForgetPassword.styles.scss';
import { useTranslation } from 'react-i18next';

export const ForgetPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [stepTwo, setStepTwo] = useState(false);
    const [formData, setFormData] = React.useState({
        email: '',
        code: '',
        password: '',
    });
    const [formErrors, setFormErrors] = useState<any>();

    const { errorSnackbar, successSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const onChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const onForgetPassword = async () => {
        setIsLoading(true);
        if (!formData.email) {
            setIsLoading(false);
            setFormErrors((prevFormData: any) => ({
                ...prevFormData,
                email: 'Email is required',
            }));
            errorSnackbar('Please enter your email');
            return;
        } else {
            setFormErrors(null);
            const response = await forgotPassword(formData.email.toLowerCase());
            if (response) {
                setIsLoading(false);
                setStepTwo(true);
            } else {
                setIsLoading(false);
                errorSnackbar(defaultErrorMessage);
            }
        }
    };

    const onForgetPasswordSubmit = async () => {
        setIsLoading(true);
        const { email, code, password } = formData;
        if (!email || !code || !password) {
            setIsLoading(false);
            errorSnackbar('Please enter all the fields');
            setFormErrors((prevFormData: any) => ({
                ...prevFormData,
                code: 'Code is required',
                password: 'Password is required',
            }));
            return;
        } else {
            setFormErrors(null);
            const response = await forgotPasswordSubmit(email, code, password);
            if (response) {
                setIsLoading(false);
                successSnackbar('Password changed successfully');
                setTimeout(() => {
                    navigate(AppRoutes.Login);
                }, 2000);
            } else {
                setIsLoading(false);
                errorSnackbar(defaultErrorMessage);
            }
        }
    };

    return (
        <div className="forget-password-page">
            <Header logoAlign="center" />
            <div className="forget-password-section">
                <h1>{t('forgot_password')}</h1>
                {!stepTwo ? (
                    <SubmitEmail
                        onChange={onChange}
                        formData={formData}
                        formErrors={formErrors}
                        onForgetPassword={onForgetPassword}
                        isLoading={isLoading}
                    />
                ) : (
                    <SubmitPassword
                        onChange={onChange}
                        formData={formData}
                        formErrors={formErrors}
                        onForgetPasswordSubmit={onForgetPasswordSubmit}
                        isLoading={isLoading}
                    />
                )}
            </div>
        </div>
    );
};
