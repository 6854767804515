import React, { FC, useEffect, useState } from 'react';

import { SEMESTER_PLAN_PRICE_ID } from '~constants';
import { useAuth, useSnackbar } from '~hooks';
import { logger } from '~utils';
import {
    getStripeUserDetails,
    semesterPlanUpgrade,
} from '~components/PaymentPlan';
import { emitSemeseterPlanEvent } from '~utils/advertisingEvents';

import './SemesterPlanPopup.styles.scss';
import { useTranslation } from 'react-i18next';

type Props = {
    open: boolean;
    onClose: () => void;
};

export const SemesterPlanPopup: FC<Props> = (props) => {
    const { open, onClose } = props;
    const [loading, setLoading] = useState(false);
    const { user, token } = useAuth();
    const [isShowSemesterPlan, setIsShowSemesterPlan] = useState(false);

    const { infoSnackbar, successSnackbar, errorSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const customerId = user?.data?.attributes['custom:stripeCustomerID'];

    const onCtaClickHandler = async () => {
        setLoading(true);
        logger.debug('Upgrade button clicked');
        infoSnackbar(`${t('upgrading_sub')}...`);
        const res = await semesterPlanUpgrade(token, customerId);

        logger.info('semester subscription result >> ' + res);
        if (res === 'Success') {
            localStorage.setItem('isSemesterModalShow', 'false');
            await emitSemeseterPlanEvent();
            successSnackbar('Semester subscription started successfully');
            window.location.reload();
        } else {
            errorSnackbar(
                'Something went wrong, try again. This is likely because your bank declined the charge, please try again with a different card.',
            );
            setLoading(false);
        }
    };

    const handleClose = () => {
        onClose();
    };

    const onNoThanksClickHandler = () => {
        localStorage.setItem('isSemesterModalShow', 'false');
        onClose();
    };

    const getDetails = async () => {
        const response = await getStripeUserDetails(token, customerId, t);
        if (response) {
            const { planId } = response;
            if (!planId || planId !== SEMESTER_PLAN_PRICE_ID) {
                setIsShowSemesterPlan(true);
            }
        }
    };

    useEffect(() => {
        if (!user || !customerId) return;
        getDetails();
    }, [user, customerId]);

    if (!open || !isShowSemesterPlan) {
        return null;
    }

    return (
        <div className="semester-plan-popup">
            <div className="semester-plan-popup-content">
                <div className="tag">{t('best_value')}</div>
                <h3>
                    {t('upgrade_to')} <span>{t('semester')}</span>
                </h3>
                <p className="regular-price">$71.99</p>
                <p className="discount-price">$39.99</p>
                <p className="billing-cycle">{t('billed_every3')}</p>
                <p className="limited-time-offer">
                    {t('save')} 44% - {t('limited_time_offer')}
                </p>
                <button
                    className="cta-btn"
                    onClick={onCtaClickHandler}
                    disabled={loading}
                >
                    {t('upgrade')}
                </button>
                <p className="no-thanks-btn" onClick={onNoThanksClickHandler}>
                    {t('no_thanks')}
                </p>
            </div>
            <div className="background-modal" onClick={handleClose}></div>
        </div>
    );
};
