export const FAQItems = [
    {
        question: 'faq1',
        answer: `faq1_answer`,
    },
    {
        question: 'faq3',
        answer: `faq3_answer`,
    },
    {
        question: 'faq4',
        answer: `faq4_answer`,
    },
    {
        question: 'faq5',
        answer: `faq5_answer`,
    },
    {
        question: 'faq6',
        answer: `faq6_answer`,
    },
    {
        question: 'faq7',
        answer: `faq7_answer`,
    },
];
