import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useAuth, useScreenSize } from '~hooks';
import { useExtensionHelper } from '~utils/extension';
import DashboardKnowledges from '~components/Dashboard/DashboardKnowledges/DashboardKnowledges';
import { HowToUse } from '~components/Dashboard/HowToUse/HowToUse';
import { Onboarding } from '~components/Onboarding';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DashboardUserInfo from '~components/Dashboard/DashboardUserInfo/DashboardUserInfo';
import { Banner } from '~components/Banner';
import { DashboardFooter } from '~components/Dashboard/DashboardFooter';
import { MobileOnboard } from '~components/mobileOnboard'; // Import MobileOnboard

import './DashboardOverview.styles.scss';

export const DashboardOverview = ({
    onboardingStatus,
}: {
    onboardingStatus: boolean;
}) => {
    const { isMobile } = useScreenSize();
    const { isExtensionInstalled } = useExtensionHelper();
    const [showOnboarding, setShowOnboarding] = useState(false);
    const { token } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onHelpButtonClick = () => {
        navigate('/dashboard/contact');
    };

    const onInstallExtensionClickHandler = () => {
        setShowOnboarding(true);
    };

    const onTestFunction = () => {
        console.log('test function clicked >>>');
        // testFuncApi(token);
    };

    useEffect(() => {
        const isOnboardingCompleted = localStorage.getItem(
            'isNewOnboardingCompleted',
        );
        if (!isOnboardingCompleted && !isExtensionInstalled) {
            setShowOnboarding(true);
        } else {
            setShowOnboarding(false);
        }
    }, [isExtensionInstalled]);

    useEffect(() => {
        if (onboardingStatus) {
            setShowOnboarding(true);
        }
    }, [onboardingStatus]);

    useEffect(() => {
        (window as any).botpressWebChat.mergeConfig({ hideWidget: false });
    }, []);

    if (isMobile && showOnboarding) {
        return <MobileOnboard />;
    }

    if (showOnboarding) {
        return (
            <Onboarding showOnboarding setShowOnboarding={setShowOnboarding} />
        );
    }

    return (
        <>
            <Container style={{ maxWidth: '1140px' }}>
                <div className="dashboard-overview">
                    <div className="dashboard-overview__content">
                        <Banner />
                        <div className="dashboard-body">
                            <DashboardUserInfo />
                            <DashboardKnowledges
                                showOnboarding={showOnboarding}
                                setShowOnboarding={setShowOnboarding}
                            />
                        </div>
                    </div>
                </div>
                <HowToUse videoMaxSize="100%" />
            </Container>
            <DashboardFooter />
        </>
    );
};
