import Cookies from 'js-cookie';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from '~routes';
import { Amplify, Hub } from 'aws-amplify';
import awsConfig from './aws-exports';
import {
    AppRoutes,
    CookieKeys,
    SENTRY_DSN,
    SENTRY_LOGGING_URL,
    SENTRY_TRACES_SAMPLE_RATE,
    STAGE_URL,
} from '~constants';
import { getCurrentEnvironment, logger, setCookie } from '~utils';
import { createBrowserHistory } from 'history';
import i18n from 'i18next';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { initReactI18next } from 'react-i18next';
import enJSON from './translations/en.json';
import esJSON from './translations/es.json';
import ptJSON from './translations/pt.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const storedLang = localStorage.getItem('i18nextLng');
console.log('storedLang', storedLang);

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: { order: ['querystring', 'navigator', 'localStorage'] },
        resources: {
            en: enJSON,
            es: esJSON,
            pt: ptJSON,
        },
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
    });

const App = () => {
    const history = createBrowserHistory();
    // Handle the different redirect URI for local/production
    const { isLocalhost, isStage } = getCurrentEnvironment();

    const sentryTargetUrls = ['localhost:3000', STAGE_URL];
    if (SENTRY_LOGGING_URL) {
        sentryTargetUrls.push(SENTRY_LOGGING_URL);
    }

    // We have two redirect URIs, and the first is for prod and second is for local
    const [localRedirectSignIn, productionRedirectSignIn, stageRedirectSignIn] =
        awsConfig.oauth.redirectSignIn.split(',');

    const [
        localRedirectSignOut,
        productionRedirectSignOut,
        stageRedirectSignOut,
    ] = awsConfig.oauth.redirectSignOut.split(',');

    const updatedAwsConfig = {
        ...awsConfig,
        // Update to use custom auth domian
        oauth: {
            ...awsConfig.oauth,
            domain:
                isLocalhost || isStage
                    ? 'sso-stage.studybuddy.gg'
                    : 'sso.studybuddy.gg',
            redirectSignIn: isLocalhost
                ? localRedirectSignIn
                : isStage
                  ? stageRedirectSignIn
                  : productionRedirectSignIn,
            redirectSignOut: isLocalhost
                ? localRedirectSignOut
                : isStage
                  ? stageRedirectSignOut
                  : productionRedirectSignOut,
        },
    };

    logger.info(
        'Using the following redirect URIs for sign in and sign out: ' +
            updatedAwsConfig.oauth.redirectSignIn +
            ' , ' +
            updatedAwsConfig.oauth.redirectSignOut,
    );

    Amplify.configure(updatedAwsConfig);

    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: sentryTargetUrls,
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    const decodeErrorMessage = (encodedStr: string) => {
        const decodedStr = decodeURIComponent(encodedStr.replace(/\+/g, '%20'));
        return decodedStr;
    };

    // We have to listen for Sign in failures BEFORE we call Google SSO to log in
    Hub.listen('auth', (data) => {
        //console.log('Auth HUB event ___', data.payload);
        if (data.payload.event === 'signIn_failure') {
            logger.error(('Sign in failure: ' + data.payload.data) as any);
            history.push(AppRoutes.Login, {
                error:
                    decodeErrorMessage(data.payload.data?.message) ||
                    'Sign in failure',
            });
            window.location.reload();
        }
    });

    const growthbook = new GrowthBook({
        apiHost: 'https://cdn.growthbook.io',
        clientKey: 'sdk-rkuD1QtAkj45U6wJ',
        enableDevMode: true,
        subscribeToChanges: true,
        trackingCallback: (experiment, result) => {
            // TODO: Use your real analytics tracking system
            console.log('Viewed Experiment', {
                experimentId: experiment.key,
                variationId: result.key,
            });
        },
    });

    useEffect(() => {
        // Load features asynchronously when the app renders
        // retrieve a cookie named sb_pricing_design_id
        const isWeeklyPricingTestCookie = Cookies.get(
            CookieKeys.weeklyPricingCookieKey,
        );

        let weeklyPricingTestUserId;

        if (!isWeeklyPricingTestCookie) {
            weeklyPricingTestUserId = Math.floor(10000000 + Math.random() * 90000000);
            setCookie(
                CookieKeys.weeklyPricingCookieKey,
                weeklyPricingTestUserId.toString(),
                999,
            );
        } else {
            weeklyPricingTestUserId = isWeeklyPricingTestCookie;
        }

        growthbook.setAttributes({
            id: weeklyPricingTestUserId,
        });
        growthbook.loadFeatures();
    }, []);

    return (
        <GrowthBookProvider growthbook={growthbook}>
            <BrowserRouter>
                <Routes />
                <ToastContainer />
            </BrowserRouter>
        </GrowthBookProvider>
    );
};

export default App;
