export const createFacebookShareLink = (urlToShare: string) => {
    const facebookBaseUrl = 'https://www.facebook.com/sharer/sharer.php?u=';
    const encodedUrl = encodeURIComponent(urlToShare);
    return facebookBaseUrl + encodedUrl;
};

export const createWhatsAppShareLink = (urlToShare: string) => {
    const baseUrl: string = 'https://api.whatsapp.com/send?text=';
    const encodedMessage: string = encodeURIComponent(urlToShare);
    return baseUrl + encodedMessage;
};

export const createTwitterShareLink = (urlToShare: string) => {
    const baseUrl = 'https://twitter.com/intent/tweet?';
    const params = new URLSearchParams({
        url: urlToShare,
    });

    return baseUrl + params.toString();
};
