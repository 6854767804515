import React, { FC } from 'react';

import SupportIcon from '../../../assets/contact-support-icon.svg';
import './WatchTutorialPopup.styles.scss';
import { Assets } from '~constants';

type Props = {
    open: boolean;
    onClose: () => void;
};

export const WatchTutorialPopup: FC<Props> = (props) => {
    const { open, onClose } = props;

    const handleClose = () => {
        onClose();
    };

    if (!open) {
        return null;
    }

    return (
        <div className="watchTutorial-popup">
            <div className="watchTutorial-content">
                <video
                    className="tutorial-video"
                    controls
                    autoPlay
                    muted={false}
                    loop={false}
                >
                    <source src={Assets.onboardingExtensionTutorial} />
                </video>
            </div>
            <div className="background-modal" onClick={handleClose}></div>
        </div>
    );
};
