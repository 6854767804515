import { Route, Routes as ReactRouterRoutes, Navigate } from 'react-router-dom';

import { AppRoutes } from '~constants';
import PrivateRoutes from './PrivateRoutes';
import { Login } from '~pages/Login';
import { Register } from '~pages/Register';
import { Dashboard } from '~pages/Dashboard';
import { DashboardProfile } from '~pages/Dashboard/DashboardProfile';
import { DashboardContact } from '~pages/Dashboard/DashboardContact';
import { PaymentDetails } from '~pages/PaymentDetails';
import { ForgetPassword } from '~pages/ForgetPassword';
import { Homepage } from '~pages/Homepage';
import { Privacy } from '~pages/Privacy';
import { Terms } from '~pages/Terms';

export const Routes = () => {
    return (
        <ReactRouterRoutes>
            <Route element={<Dashboard />} path={AppRoutes.Dashboard}>
                <Route path="profile" element={<DashboardProfile />} />
                <Route path="contact" element={<DashboardContact />} />
            </Route>
            <Route path="*" element={<Navigate to={AppRoutes.Home} />} />

            <Route path={AppRoutes.Home} element={<Homepage />} />
            <Route path={AppRoutes.Privacy} element={<Privacy />} />
            <Route path={AppRoutes.Terms} element={<Terms />} />
            <Route
                path={AppRoutes.Signin_Redirect}
                element={<PaymentDetails />}
            />
            <Route path={AppRoutes.Signout_Redirect} element={<Login />} />
            <Route element={<Login />} path={AppRoutes.Login} />
            <Route element={<Register />} path={AppRoutes.Register} />
            <Route
                element={<ForgetPassword />}
                path={AppRoutes.ForgetPassword}
            />
        </ReactRouterRoutes>
    );
};
