import React, { useEffect } from 'react';

import { useAuth, useSnackbar } from '~hooks';
import { loadInvoices } from '~components/PaymentPlan';
import type { Invoice } from './BillingSummary.types';
import { logger } from '~utils';
import { Loader } from '~components/Loader';
import { BillingItem } from './BillingSummaryItem';

import './BillingSummary.styles.scss';
import { useTranslation } from 'react-i18next';

export const BillingSummary = () => {
    const [invoices, setInvoices] = React.useState<
        Invoice[] | null | undefined
    >(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    const { t } = useTranslation();
    const { user, token } = useAuth();
    const { errorSnackbar } = useSnackbar();

    const getInvoices = async (token: string, customerId: string) => {
        setLoading(true);
        try {
            const allInvoices = await loadInvoices(token, customerId);
            logger.debug('Innn >>', allInvoices);
            setInvoices(allInvoices);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            logger.error(error as any);
            errorSnackbar('Error loading the invoices');
        }
    };

    useEffect(() => {
        const customerId = user?.data?.attributes['custom:stripeCustomerID'];

        if (!customerId || !token) {
            return;
        }

        getInvoices(token, customerId);
    }, [user]);

    return (
        <div className="billing-summary">
            <h1>{t('summary')}</h1>
            <h4>{t('summary_of_payments')}</h4>
            <div className="summary-section">
                {loading && <Loader />}
                {invoices && invoices.length
                    ? invoices.map((invoice: Invoice) => (
                          <BillingItem key={invoice.id} data={invoice} />
                      ))
                    : null}
            </div>
        </div>
    );
};
