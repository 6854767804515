import React, { useState } from 'react';

// import { icons } from '~constants';
import { useAuth, useScreenSize } from '~/hooks';
import StudybuddyLogoBlack from '../../../assets/studybuddy-logo-black.svg';
import homeIconWhite from '../../../assets/home-icon_white.svg';
// import { SocialLinks } from '~components/SocialLinks';

import './WelcomeHeader.styles.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const DashboardHeader = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { user } = useAuth();
    const { logout } = useAuth();
    const { isMobile } = useScreenSize();
    const navigate = useNavigate();

    const name = user?.data?.attributes.given_name;
    const { t } = useTranslation();

    const handleMenuButtonClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const homeClickHandler = () => {
        navigate('/');
    };
    const logoutClickHandler = () => {
        logout();
    };

    return (
        <nav className="dashboard-header">
            <div className="dashboard-header-container">
                <div className="dashboard-navbarTop">
                    <a href="/">
                        <img
                            src={StudybuddyLogoBlack}
                            alt="Studybuddy Logo"
                            className="dashboard-navbarBrand"
                        />
                    </a>
                    <button
                        className={isMenuOpen ? 'closeIcon' : 'menuIcon'}
                        onClick={handleMenuButtonClick}
                    >
                        <img
                            src={
                                isMenuOpen
                                    ? '/assets/images/cross_icon.svg'
                                    : '/assets/images/hamburger_menu.svg'
                            }
                            alt="menu button"
                        />
                    </button>
                </div>

                <div className="dashboard-navbarButtons">
                    <button
                        onClick={logoutClickHandler}
                        className={`outlinedButton`}
                    >
                        <img
                            className="exitIcon"
                            src="/assets/images/log-out_dashboard.svg"
                            alt="exit_icon"
                        />
                        {t('Log Out')}
                    </button>
                </div>

                {/* mobile  */}
                <nav
                    className={`dashboardNavbarListMobile ${isMenuOpen ? 'open' : ''}`}
                >
                    <button className="filledButton" onClick={homeClickHandler}>
                        <img
                            className="homeIcon"
                            src={homeIconWhite}
                            alt="home_icon"
                        />
                        <a href="">{t('Home')}</a>
                    </button>
                    <button
                        className="outlinedButton-mobile"
                        onClick={logoutClickHandler}
                    >
                        <img
                            src="/assets/images/log-out_dashboard.svg"
                            alt="logout_icon"
                        />
                        {t('Log Out')}
                    </button>
                </nav>
            </div>
        </nav>
    );
};
