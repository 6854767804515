import React, { FC, useState } from 'react';

import './Tools.styles.scss';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from '~hooks';
import ToolsCard from './ToolsCard/ToolsCard';

// images
import StudybuddyWriterIcon from '~assets/studybuddy-writer.svg';
import StudybuddyTurboIcon from '~assets/studybuddy-turbo.svg';
import StudybuddyAppIcon from '~assets/studybuddy-app.svg';

import WriterDescriptionDesktop from '~/assets/writer-description-desktop.svg';
import WriterDescriptionMobile from '~/assets/writer-description-mobile.svg';
import AppDescriptionMobile from '~/assets/app-description-mobile.svg';
import AppDescriptionDesktop from '~/assets/app-description-desktop.svg';

import TurboImgDesktop from '~assets/turbo-img-desktop.svg';
import TurboImgMobile from '~assets/turbo-img-mobile.svg';

import { useToolsCardData } from './hooks/useToolsCardData';

//types
import { ToolsCardType } from './ToolsSection.types';

export const Tools: FC<any> = (props) => {
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();
    const toolsCardData = useToolsCardData();

    return (
        <div className="tools-section" id="tools-section">
            {/* Title section */}
            {isMobile ? (
                <h2 className="tools-title">
                    Studybuddy <span>{t('Tools')}</span>
                </h2>
            ) : (
                <h2 className="tools-title">
                    {t('Plus get access to other')} <span>{t('Tools')}</span>
                </h2>
            )}
            {/* Cards section */}
            <div className="tools-card-container">
                {toolsCardData.map((tools: ToolsCardType) => {
                    return <ToolsCard {...tools} key={tools.id} />;
                })}
            </div>
        </div>
    );
};
