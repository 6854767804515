import { useNavigate, useSearchParams } from 'react-router-dom';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import Youtube, { YouTubeProps } from 'react-youtube';

import { AppRoutes, Assets, icons } from '~constants';
import { videoOptions } from './HowToUse.constants';
import type { HowToUseProps } from './HowToUse.types';
import { Loader } from '~components/Loader';

import './HowToUse.styles.scss';
import { useTranslation } from 'react-i18next';

export const HowToUse: FC<HowToUseProps> = (props) => {
    const [isVideoPopupShow, setIsVideoPopupShow] = useState(false);
    const [showThumbnail, setShowThumbnail] = useState(true);
    const [videoPlay, setvideoPlay] = useState(false);
    const [loading, setLoading] = useState(false);

    const { videoMaxSize } = props;
    const navigate = useNavigate();
    const [searchParams, _] = useSearchParams();

    const onPlayerStateChange = (event: any) => {
        if (event.data === 0) {
            setShowThumbnail(true);
            setvideoPlay(false);
        }
    };

    const onPlayButtonClick = () => {
        setLoading(true);
        setvideoPlay(true);
    };

    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        setShowThumbnail(false);
        setLoading(false);
    };

    const handleClose = () => {
        navigate(AppRoutes.Dashboard);
        setIsVideoPopupShow(false);
    };

    useEffect(() => {
        if (searchParams.get('how-to-use')) {
            setIsVideoPopupShow(true);
        } else {
            setIsVideoPopupShow(false);
        }
    }, [searchParams]);

    const { t } = useTranslation();

    if (!isVideoPopupShow) {
        return null;
    }

    return (
        <div className="howToUse">
            <div className="content">
                <h1>{t('how_to_use')}</h1>
                <p>{t('how_to_use_description')}</p>
                <div
                    className="video-section"
                    style={{ maxHeight: videoMaxSize }}
                >
                    {showThumbnail && (
                        <div
                            className="video-thumbnail"
                            onClick={onPlayButtonClick}
                        >
                            <img src={Assets.videoThumbnail} alt="thumbnail" />
                            {loading ? (
                                <div className="loading">
                                    <Loader />
                                </div>
                            ) : (
                                <img
                                    src={icons.playbtn}
                                    className="play-btn"
                                    alt="play"
                                />
                            )}
                        </div>
                    )}

                    {videoPlay && (
                        <div className={`youtube-trailer`}>
                            <Youtube
                                className={`youtube-trailer`}
                                videoId="cAWoY8bqO2g"
                                onStateChange={onPlayerStateChange}
                                opts={videoOptions}
                                onPlay={onPlayerReady}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div onClick={handleClose} className="modal-overlay"></div>
        </div>
    );
};
