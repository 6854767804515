import { API } from 'aws-amplify';
import { API_NAME_STRIPE_AWS, USER_NAME_UPDATE_API } from '~constants';
import { logger } from '~utils';

type userData = {
    firstName: string;
    lastName: string;
}

export const updateUserApi = async (token: string, userData: userData) => {
    const { firstName, lastName } = userData;
    try {
        const response = await API.post(API_NAME_STRIPE_AWS, USER_NAME_UPDATE_API, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: {
                firstName: firstName,
                lastName: lastName,
            },
        });
        const msg = response.message;
        return msg;
    } catch (err: any) {
        logger.error(err);
    }
};