import { Link } from 'react-router-dom';
import { Container, Form } from 'react-bootstrap';

import { icons, TERMS_OF_SERVICE_URL } from '~constants';
import { inputFieldStyles } from './Register.constants';
import type { FC } from 'react';
import { checkRegisterDisable } from '~pages/Register/Register.functions';
import type { RegisterFormProps } from './RegisterForm.types';
import { InputField } from '~components/InputField';
import { Loader } from '~components/Loader';

import './RegisterForm.styles.scss';
import { SocialLogins } from '~components/SocialLogins';
import { useScreenSize } from '~hooks';
import { useTranslation } from 'react-i18next';

export const RegisterForm: FC<RegisterFormProps> = (props) => {
    const {
        onChange,
        formData,
        formErrors,
        onEmailBlur,
        onSubmit,
        isButtonLoading,
        isEmailExist,
    } = props;

    const isCtaDisable: boolean = checkRegisterDisable(
        formErrors,
        isEmailExist,
    );
    const { isMobile } = useScreenSize();

    const onTermsClick = () => {
        window.open(TERMS_OF_SERVICE_URL);
    };

    const { t } = useTranslation();

    return (
        <Container className="register-form">
            <div className="signup-left-align">
                {!isMobile && <p className="small-sign-up">{t('sign_up')}</p>}
                <h1>
                    {t('create_new_account')}
                    {!isMobile ? '.' : ''}
                </h1>
                <h3>
                    {t('already_a_member')}{' '}
                    <Link to="/login">{t('login')}</Link>
                </h3>
            </div>
            <Form className="form w-100" onSubmit={onSubmit}>
                <InputField
                    name="email"
                    type="email"
                    label={t('email')}
                    id="emailInput"
                    value={formData.email}
                    onChange={onChange}
                    placeholder={t('enter_email')}
                    style={inputFieldStyles}
                    error={formErrors?.email}
                    onBlur={onEmailBlur}
                />
                <InputField
                    name="password"
                    type="password"
                    label={t('password')}
                    minLength={8}
                    value={formData.password}
                    onChange={onChange}
                    placeholder={t('enter_password')}
                    style={inputFieldStyles}
                    error={formErrors?.password}
                />
                <button
                    className="cta-btn"
                    type="submit"
                    disabled={isCtaDisable || isButtonLoading}
                >
                    {isButtonLoading ? (
                        <Loader color="#fff" />
                    ) : (
                        <span>
                            {isEmailExist
                                ? t('login')
                                : t('create_new_account')}
                        </span>
                    )}
                </button>
            </Form>
            <div className="divider">
                <span>{t('or')}</span>
            </div>
            <div className="social-logins-section">
                <SocialLogins isGoogleSignup />
            </div>
            {isMobile && (
                <p className="terms">
                    {t('by_creating')}
                    <br />
                    <span onClick={onTermsClick}>{t('agree_to_terms')}</span>
                </p>
            )}
            {!isMobile && (
                <p className="terms">
                    {t('by_creating')}
                    <span onClick={onTermsClick}>{t('agree_to_terms')}</span>
                </p>
            )}
        </Container>
    );
};
