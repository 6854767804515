import { API } from "aws-amplify";
import { API_NAME_STRIPE_AWS, VIRAL_SHARE_CREDIT_API } from "~constants";
import { logger } from "~utils";

export const giveViralShareCredit = async (
    token: string | undefined,
    customerId: string,
    forcedShare?: boolean
): Promise<void> => {
    if (!token || !customerId) {
        return;
    }
    try {
        const response = await API.post(
            API_NAME_STRIPE_AWS,
            VIRAL_SHARE_CREDIT_API,
            {
                _headers: {
                    Authorization: `Bearer ${token}`,
                },
                get headers() {
                    return this._headers;
                },
                set headers(value) {
                    this._headers = value;
                },
                body: {
                    customerId,
                    forcedShare: forcedShare || false,
                },
            },
        );
        logger.debug('Viral share credit response:', response);
    } catch (err: any) {
        logger.error(err);
    }
};