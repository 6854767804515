import { Auth } from "aws-amplify";
import { logger } from "~utils";

export const forgotPassword = async (email: string) => {
    try {
        const data = await Auth.forgotPassword(email.toLowerCase());
        return data;
    } catch (err: any) {
        logger.error(err);
        return null;
    }
};

// Collect confirmation code and new password
export const forgotPasswordSubmit = async (
    email: string,
    code: string,
    newPassword: string,
) => {
    try {
        const data = await Auth.forgotPasswordSubmit(
            email.toLowerCase(),
            code,
            newPassword,
        );
        return data;
    } catch (err: any) {
        logger.error(err);
        return null;
    }
};
