import React from 'react';
import Marquee from 'react-fast-marquee';

import { CompanyLogoItems } from './CompanyLogos.constants';

import './CompanyLogos.styles.scss';
import { useScreenSize } from '~hooks';
import { useTranslation } from 'react-i18next';

export const CompanyLogos = () => {
    const { isMobile } = useScreenSize();
    const { t } = useTranslation();

    return (
        <div id="companies">
            {isMobile ? (
                <h3>
                    {t('all_platforms')} <span>{t('supported')}</span>
                </h3>
            ) : (
                <h3>
                    {t('all_major_platforms')} <span>{t('supported')}</span>
                </h3>
            )}
            <div className="companies-marquee-section">
                <Marquee className="company-marquee-container">
                    {CompanyLogoItems.map((item, index) => (
                        <div className="company-marquee-item" key={index}>
                            <img src={item} alt="institution" />
                        </div>
                    ))}
                </Marquee>
            </div>
            {/* <img id="companyLogoBG" src={companyLogoBG} alt="companies" /> */}
        </div>
    );
};
