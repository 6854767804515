export const inputFieldStyles = {
    labelFontSize: '14px',
    labelMarginBottom: '8px',
    labelColor: 'rgba(14, 21, 58, 1)',
    height: '52px',
    fontSize: '14px',
    marginBottom: '0px',
    color: '#0E153A',
    padding: '14px 20px',
    borderRadius: '10px',
    border: '1px solid #EBEBEB',
    background: '#FFF',
    boxShadow: '0px',
};

export const textAreaFieldStyles = {
    height: '115px',
    fontSize: '14px',
    marginBottom: '0px',
    color: '#0E153A',
    padding: '14px 20px',
    borderRadius: '10px',
    border: '1px solid #EBEBEB',
    background: '#FFF',
    boxShadow: '0px',
};
