import React from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';

// import { SwiperNextBtn } from './SwiperControllers/SwiperNextBtn';
// import { SwiperPrevBtn } from './SwiperControllers/SwiperPrevBtn';

import './HowItWorks.styles.scss';

import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import SwiperCore from 'swiper';
import { useTranslation } from 'react-i18next';

SwiperCore.use([Pagination]);

export const HowItWorks = () => {
    const { t } = useTranslation();

    return (
        <div id="howItWorks">
            <h1 className="howItWorksTitle">
                {t('how')} <span>{t('it_works')}</span>
            </h1>
            <div className="howItWorksContainer">
                <div
                    style={{ justifySelf: 'self-end' }}
                    className="howItWorksItem"
                >
                    <div className="itemContent">
                        <div className="itemHeader">
                            <h3>
                                {t('injects_into')} {t('learning_platform')}
                            </h3>
                            <p>
                                {t('when_enabled')}{' '}
                                <span>{t('ask_studybuddy_btn')}</span>{' '}
                                {t('one_click_explanations')}
                            </p>
                        </div>
                        <video
                            id="buttonVideo"
                            className="videoContent"
                            preload="auto"
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source
                                src="https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/buttonVideo_new.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>

                <div className="howItWorksItem">
                    <div className="itemContent">
                        <div className="itemHeader">
                            <h3>
                                {t('screenshot')} {t('any_question')}
                            </h3>
                            <p>
                                {t("don't_use_button_lowkey")}{' '}
                                <span>{t('screenshot...')}</span>{' '}
                                {t('and_wallah!')}
                            </p>
                        </div>
                        <video
                            className="videoContent"
                            id="screenshotVideo"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                            preload="auto"
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source
                                src="https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/screenshotVideo_new.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>

                <div
                    style={{ justifySelf: 'self-end', marginTop: '16px' }}
                    className="howItWorksItem"
                >
                    <div className="itemContent">
                        <div className="itemHeader">
                            <h3>
                                {t('100%')} {t('undetectable')}
                            </h3>
                            <p>
                                {t('your_info_is_safe')} <br />
                                <strong>{`Studybuddy`}</strong>{' '}
                                {t('prevents_extension_detection')}
                            </p>
                        </div>
                        <video
                            className="videoContent"
                            id="hiddenVideo"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                            preload="auto"
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source
                                src="https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/Hidden.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>

                <div style={{ marginTop: '16px' }} className="howItWorksItem">
                    <div className="itemContent">
                        <div className="itemHeader">
                            <h3>
                                {t('answers')} {t('anything_&_everything')}
                            </h3>

                            <p>
                                <span>{t('quiz')}</span>{' '}
                                <span>{t('test')}</span>{' '}
                                <span>{t('homework')}</span>{' '}
                                {t('or_problem_set')} <span>{t('math')}</span>{' '}
                                {t('ques_no_problem')}{' '}
                                <span>{t('images')}</span>{' '}
                                <span>{t('graphs')}</span>{' '}
                                <span>{t('diagrams')}</span>
                                {t('?_easy_peasy')}
                            </p>
                        </div>
                        <video
                            className="videoContent"
                            id="anythingVideo"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                            preload="auto"
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source
                                src="https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/Works_on_anything.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="howItWorksMobileContainer">
                <div className="howItWorksItem">
                    <div className="itemHeader">
                        <h3>
                            {t('injects_into')}{' '}
                            <span>{t('learning_platform')}</span>
                        </h3>
                        <p>
                            {t('when_enabled')}{' '}
                            <span>{t('ask_studybuddy_btn')}</span>{' '}
                            {t('one_click_explanations')}
                        </p>
                    </div>
                    <div className="itemContent">
                        <video
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                            id="buttonVideo"
                            className="videoContent"
                            preload="auto"
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source
                                src="https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/buttonVideo_new.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
                <div className="howItWorksItem">
                    <div className="itemHeader">
                        <h3>
                            <span>{t('screenshot')}</span> {t('any_question')}
                        </h3>
                        <p>
                            {t("don't_use_button_lowkey")}{' '}
                            <span>{t('screenshot...')}</span> {t('and_wallah!')}
                        </p>
                    </div>
                    <div className="itemContent">
                        <video
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                            id="screenshotVideo"
                            className="videoContent"
                            preload="auto"
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source
                                src="https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/screenshotVideo_new.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>

                <div className="howItWorksItem">
                    <div className="itemHeader">
                        <h3>
                            <span>{t('answers')}</span>{' '}
                            {t('anything_&_everything')}
                        </h3>
                        <p>
                            <span>{t('quiz')}</span> <span>{t('test')}</span>{' '}
                            <span>{t('homework')}</span> {t('or_problem_set')}{' '}
                            <span>{t('math')}</span> {t('ques_no_problem')}{' '}
                            <span>{t('images')}</span>{' '}
                            <span>{t('graphs')}</span>{' '}
                            <span>{t('diagrams')}</span>
                            {t('?_easy_peasy')}
                        </p>
                    </div>
                    <div className="itemContent">
                        <video
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                            className="videoContent"
                            id="anythingVideo"
                            preload="auto"
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source
                                src="https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/Works_on_anything.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>

                <div className="howItWorksItem">
                    <div className="itemHeader">
                        <h3>
                            <span>{t('100%')}</span> {t('undetectable')}
                        </h3>
                        <p>
                            {t('your_info_is_safe')} <br />
                            <strong>{`Studybuddy`}</strong>{' '}
                            {t('prevents_extension_detection')}
                        </p>
                    </div>
                    <div className="itemContent">
                        <video
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                            }}
                            className="videoContent"
                            id="anythingVideo"
                            preload="auto"
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source
                                src="https://pub-9a3f5ea2647b46429f844effdb2e84ac.r2.dev/Hidden.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
            </div>
        </div>
    );
};
