import { API } from 'aws-amplify';
import {
    API_NAME,
    API_NAME_STRIPE_AWS,
    GET_CARD_DETAILS_API,
    GET_STRIPE_USER_DETAILS_API,
    LIST_INVOICES_API,
    RESUBSCRIBE_SUBSCRIPTION_API,
    SEMESTER_PLAN_UPGRADE_API,
} from '~constants';
import { computeDaysLeft, covertTimestampToDate, logger } from '~utils';
import { Invoice } from '~components/BillingSummary/BillingSummary.types';
import { BillingDetails, Discounts } from './PaymentPlan.types';

export const formatPaymentStatus = (
    status: string | undefined,
    t: (key: string) => string,
) => {
    if (!status) return null;

    if (status.startsWith('cancels')) {
        const date = status.split(': ')[1];
        const dateInt = parseInt(date);
        return `${t('cancels_on')} ${covertTimestampToDate(dateInt)}`;
    } else if (status.startsWith('paused')) {
        const date = status.split(': ')[1];
        const dateInt = parseInt(date);
        return `${t('paused_until')} ${covertTimestampToDate(dateInt)}`;
    } else if (status.startsWith('failed')) {
        return t('payment_failed');
    }

    return t('Inactive');
};

export const formatDaysRemaining = (
    data: Partial<BillingDetails>,
    daysRemainingString: string,
) => {
    try {
        if (data.daysLeft != null && Number.isInteger(data.daysLeft)) {
            return `${data.daysLeft} ${daysRemainingString}`;
        }
        if (data.metadata?.introTrialEnd != null) {
            const daysRemaining = computeDaysLeft(data.metadata.introTrialEnd);
            return `${daysRemaining} ${daysRemainingString}`;
        }
    } catch (e: any) {
        logger.error(e);
    }
    return 'Active';
};

export const formatCreditBalance = (
    balance: string | undefined,
    t: (key: string) => string,
): string => {
    if (!balance) {
        return '';
    }
    try {
        const parsedBalance = parseInt(balance);
        // Don't show debits
        if (parsedBalance >= 0) {
            return '';
        }
        // They have a credit
        return `$${parsedBalance / -100.0} Credit`;
    } catch (e) {
        logger.error(new Error(`${t('')}: ` + balance));
    }
    return '';
};

export const filterInvoices = (invoices: Invoice[]) => {
    if (!invoices) return null;
    return invoices.filter(
        (invoice: any) =>
            invoice.status !== 'void' && invoice.status !== 'open',
    );
};

export const getStripeUserDetails = async (
    token: string | undefined,
    customerId: string,
    t: (key: string) => string,
) => {
    if (!token) {
        return;
    }
    try {
        const response = await API.get(
            API_NAME_STRIPE_AWS,
            GET_STRIPE_USER_DETAILS_API,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                queryStringParameters: {
                    customerId: customerId,
                },
            },
        );

        logger.debug('billingData >>', response);
        // console.log('billingData >>', response);

        const planType = t('unlimited_access');
        const planEnd = response.subscriptions?.data[0]?.current_period_end;
        const daysLeft = computeDaysLeft(planEnd);
        const billingEmail = response.customer.email;
        const defaultPaymentMethod =
            response.customer.invoice_settings.default_payment_method;
        const metadata = response.customer?.metadata;
        const balance = response.customer?.balance;
        const subscriptions = response.subscriptions?.data;
        const couponPercentOff =
            response.customer?.discount?.coupon?.percent_off;
        const planId = response.subscriptions?.data[0]?.plan.id;
        const planAmount = response.subscriptions?.data[0]?.plan.amount_decimal;
        const planAmountToShow = planAmount / 100;
        const paymentMethodId = response?.customer?.invoice_settings?.default_payment_method;

        // console.log('planId:', planId);
        logger.debug('plan amount', planAmountToShow);
        // console.log('plan amount', planAmountToShow);

        return {
            planType,
            planEnd,
            planId,
            daysLeft,
            billingEmail,
            updatePaymentUrl: response.paymentUpdateFlowUrl.url,
            defaultPaymentMethod,
            metadata,
            balance,
            subscriptions,
            couponPercentOff,
            planAmountToShow,
            paymentMethodId
        };
    } catch (err: any) {
        logger.error(err);
    }
};

export const loadInvoices = async (token: string, customerId: string) => {
    try {
        const response = await API.post(
            API_NAME_STRIPE_AWS,
            LIST_INVOICES_API,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                queryStringParameters: {
                    customerId: customerId,
                },
            },
        );
        logger.debug('loadInvoices response:', response.invoices);
        const invoices = filterInvoices(response.invoices);
        return invoices;
    } catch (err: any) {
        logger.error(err);
    }
};

export const loadCardDetails = async (
    token: string | undefined,
    defaultSourceId: string,
) => {
    if (!token) {
        return;
    }
    try {
        const response = await API.get(
            API_NAME_STRIPE_AWS,
            GET_CARD_DETAILS_API,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                queryStringParameters: {
                    defaultSourceId: defaultSourceId,
                },
            },
        );
        logger.debug('response card>>:', response);
        return response.card;
    } catch (err: any) {
        logger.error(err);
    }
};

export const reSubscription = async (
    token: string | undefined,
    customerId: string,
) => {
    if (!token) {
        return;
    }
    try {
        const response = await API.post(
            API_NAME_STRIPE_AWS,
            RESUBSCRIBE_SUBSCRIPTION_API,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    customerId,
                },
            },
        );
        logger.debug('Resubscribe subscription response:', response);
        return response;
    } catch (err: any) {
        logger.error(err);
        return null;
    }
};

export const semesterPlanUpgrade = async (
    token: string | undefined,
    customerId: string,
) => {
    if (!token) {
        return;
    }
    try {
        const response = await API.post(API_NAME, SEMESTER_PLAN_UPGRADE_API, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: {
                customerId,
            },
        });
        logger.debug('Semester plan upgrade response:', response);
        return response;
    } catch (err: any) {
        logger.error(err);
        return null;
    }
};

export const isLifetime = (data: Partial<BillingDetails>) => {
    return data.metadata?.lifetime;
};

export const hasUserClaimedDiscount = (
    metadata: Partial<Discounts>,
): boolean => {
    if (!metadata) return false;
    // Adding the false since TS is dumb with the casts
    return (
        metadata.lifetime ||
        metadata.claimedDiscount ||
        metadata.claimedFreeMonth ||
        false
    );
};
