export const inputFieldStyles = {
    labelFontSize: '14px',
    labelMarginBottom: '4px',
    labelColor: '#F8FAFB',
    height: '48px',
    fontSize: '14px',
    marginBottom: '8px',
    color: 'rgba(255, 255, 255, 0.56)',
    padding: '14px 20px',
    borderRadius: '30px',
    border: '1px solid rgba(255, 255, 255, 0.08)',
    background: 'rgba(255, 255, 255, 0.04)',
    boxShadow: '0px 0px 12px 2px rgba(255, 255, 255, 0.04) inset',
};
