import React from 'react';

import type { FC } from 'react';
import type { SubmitPasswordProps } from './SubmitPassword.types';
import { inputFieldStyles } from '../ForgetPassword.constants';
import { InputField } from '~components/InputField';
import { Loader } from '~components/Loader';

import './SubmitPassword.styles.scss';

export const SubmitPassword: FC<SubmitPasswordProps> = (props) => {
    const {
        onChange,
        formData,
        formErrors,
        onForgetPasswordSubmit,
        isLoading,
    } = props;

    return (
        <div className="submit-password-section">
            <h2>Check your email for the code</h2>
            <InputField
                name="code"
                type="text"
                label="Reset Code"
                value={formData.code}
                onChange={onChange}
                placeholder="Enter your code"
                style={inputFieldStyles}
                error={formErrors?.code}
            />
            <InputField
                name="password"
                type="password"
                label="Password"
                value={formData.password}
                onChange={onChange}
                placeholder="Enter your password"
                style={inputFieldStyles}
                error={formErrors?.password}
            />
            <button onClick={onForgetPasswordSubmit} className="cta-btn">
                {isLoading ? <Loader color="#fff" /> : <span>Submit</span>}
            </button>
        </div>
    );
};
