import React from 'react';

import { Header } from '~pages/Homepage/Header';
import { Footer } from '~pages/Homepage/Footer';

import './Privacy.styles.scss';

export const Privacy = () => {
    return (
        <div className="privacy-page">
            <Header />
            <div className="legals">
                <h1>Privacy Policy</h1>
                <h2 className="dates">Updated: August 02, 2024</h2>
                <h2 className="one">AGREEMENT TO OUR LEGAL TERMS</h2>
                <p className="para">
                    Welcome to Studybuddy2 LLC ("we," "us," or "our"). We
                    operate the website{' '}
                    <a href="https://studybuddy.gg/" style={{ color: 'blue' }}>
                        https://studybuddy.gg/
                    </a>{' '}
                    and the associated browser extension (collectively referred
                    to as the "Service"). This Privacy Policy outlines our
                    practices regarding the collection, use, and disclosure of
                    your personal data when you use our Service, as well as the
                    choices you have regarding your information.
                    <br />
                    <br />
                    We use your data to deliver and enhance our Service. By
                    accessing or using our Service, you consent to the
                    collection and use of information as described in this
                    Privacy Policy. Terms defined in this Privacy Policy carry
                    the same meanings as those in our Terms and Conditions,
                    which can be reviewed at <a href="https://studybuddy.gg/" style={{ color: 'blue' }}>
                        https://studybuddy.gg/
                    </a>
                </p>
                <p className="exp">
                    We recommend that you print a copy of these Legal Terms for
                    your records.
                </p>
                <h2 className="one">DEFINITIONS</h2>
                <p className="para">
                    <strong>Service:</strong> Service is the
                    https://studybuddy.gg/ website and browser extension
                    operated by Studybuddy2 LLC
                    <br />
                    <strong>Personal Data:</strong> Personal Data means data
                    about a living individual who can be identified from those
                    data (or from those and other information either in our
                    possession or likely to come into our possession).
                    <br />
                    <strong>Usage Data:</strong> Usage Data is data collected
                    automatically either generated by the use of the Service or
                    from the Service infrastructure itself (for example, the
                    duration of a page visit).
                    <br />
                    <strong>Cookies:</strong> Cookies are small files stored on
                    your device (computer or mobile device).
                    <br />
                    <strong>Data Controller:</strong> Data Controller means the
                    natural or legal person who (either alone or jointly or in
                    common with other persons) determines the purposes for which
                    and the manner in which any personal information are, or are
                    to be, processed. For the purpose of this Privacy Policy, we
                    are a Data Controller of your Personal Data.
                    <br />
                    <strong>
                        Data Processors (or Service Providers):
                    </strong>{' '}
                    Data Processor (or Service Provider) means any natural or
                    legal person who processes the data on behalf of the Data
                    Controller. We may use the services of various Service
                    Providers in order to process your data more effectively.
                    <br />
                    <strong>Data Subject (or User):</strong> Data Subject is any
                    living individual who is using our Service and is the
                    subject of Personal Data.
                    <br />
                    <strong>Information Collection and Use:</strong> We collect
                    several different types of information for various purposes
                    to provide and improve our Service to you.
                    <br />
                </p>
                <br />
                <h2 className="one">USER DATA</h2>
                <p className="para">
                    We collect the following data from our users: <br />
                    <br />
                    <strong>Personal Data:</strong> While using our Service, we
                    may ask you to provide us with certain personally
                    identifiable information that can be used to contact or
                    identify you ("Personal Data"). Personally identifiable
                    information may include, but is not limited to, your email
                    address.
                    <br />
                    <br />
                    <strong>Data Management:</strong> We maintain certain data
                    that you transmit to the Studybuddy Services for the purpose
                    of managing the performance of the Services, as well as data
                    related to your use of the Services. Although we perform
                    regular routine backups, you are solely responsible for all
                    data transmitted or related to any activity you undertake
                    using the Services. We shall have no liability for any loss
                    or corruption of such data, and you waive any right of
                    action against us arising from any such loss or corruption.
                </p>
                <br />
                <h2 className='one'>COOKIES</h2>
                <p className="para">
                    <strong>Usage Data:</strong> We collect information about
                    how you interact with our Service, known as "Usage Data."
                    This may include details such as your device's IP address,
                    browser type and version, the pages you visit, the date and
                    time of your visits, the time spent on each page, unique
                    device identifiers, and other diagnostic data.
                    <br />
                    <br />
                    <strong>Cookies and Tracking Technologies:</strong> To
                    enhance your experience and analyze usage, we use cookies
                    and similar tracking technologies. Cookies are small files
                    placed on your device that help us track activity and
                    preferences. We also use other tracking technologies like
                    beacons, tags, and scripts. You can configure your browser
                    to refuse cookies or alert you when cookies are being sent.
                    However, disabling cookies may affect the functionality of
                    certain parts of our Service.
                    <br />
                    <br />
                    <strong>Authentication Information:</strong> When you create
                    an account, we collect your credentials such as passwords,
                    security questions, and personal identification numbers
                    (PINs) to ensure secure access to our services.
                    <br />
                    <br />
                    <strong>Website Content:</strong> We gather and store the
                    content you provide, including text, images, sounds, videos,
                    and hyperlinks, to deliver and improve our services.
                    <br />
                    <br />
                    <strong>Data Use and Privacy:</strong> We are committed to
                    protecting your privacy and will not sell or transfer your
                    data to third parties outside of the approved use cases. We
                    use your data solely to provide and enhance our services,
                    communicate with you regarding your account, and comply with
                    legal obligations. We do not use or transfer your data for
                    unrelated purposes.
                    <br />
                    <br />
                    <strong>Security Measures:</strong> We implement
                    comprehensive security measures, including technical,
                    administrative, and physical safeguards, to protect your
                    data from unauthorized access, use, or disclosure. We
                    regularly assess our security practices to ensure robust
                    protection.
                    <br />
                    <br />
                    <strong>Cookies We Use:</strong>
                    <br />
                    <div className="para-list">
                        • Session Cookies: Essential for the operation of our
                        Service.
                        <br />
                        • Preference Cookies: To remember your preferences and
                        settings.
                        <br />• Security Cookies: To ensure the security of our
                        Service.
                    </div>
                </p>
                <h2 className="one">USE OF DATA</h2>
                <p className="para">
                    Studybuddy2 LLC uses the collected data for a range of
                    purposes to enhance and manage our Service:
                    <br />
                    <br />
                    <div className="para-list">
                        <strong>• Service Delivery and Maintenance:</strong> To
                        provide, operate, and maintain our Service efficiently.
                        <br />
                        <strong>• Updates and Notifications:</strong> To inform
                        you about important changes or updates to our Service.
                        <br />
                        <strong>• Interactive Features:</strong> To enable and
                        support your participation in interactive features when
                        you choose to engage with them.
                        <br />
                        <strong>• Customer Support: </strong>To offer assistance
                        and support for any issues or inquiries you may have.
                        <br />
                        <strong>• Service Improvement:</strong> To gather
                        insights and perform analysis to enhance the quality and
                        functionality of our Service.
                        <br />
                        <strong>• Usage Monitoring:</strong> To track and
                        monitor how our Service is used, helping us understand
                        user behavior and improve performance.
                        <br />
                        <strong>• Issue Detection and Prevention:</strong> To
                        identify, prevent, and resolve technical problems to
                        ensure a smooth user experience.
                    </div>
                </p>

                <h2 className="one">
                    Legal Basis for Processing Personal Data under the General
                    Data Protection Regulation (GDPR)
                </h2>
                <p className="para">
                    If you are located in the European Economic Area (EEA),
                    Studybuddy2 LLC processes your personal information based on
                    the following legal grounds:
                    <br />
                    <br />
                    <div className="para-list">
                        <strong>• Contractual Necessity: </strong> We process
                        your data to fulfill our contract with you.
                        <br />
                        <strong>• Consent: </strong> We process your data when
                        you have given us explicit permission.
                        <br />
                        <strong>• Legitimate Interests: </strong> TWe process
                        your data based on our legitimate interests, provided
                        these interests are not overridden by your rights.
                        <br />
                        <strong>• Payment Processing: </strong>We process your
                        data to handle transactions and payments.
                        <br />
                        <strong>• Legal Compliance: </strong> We process your
                        data to comply with legal obligations.
                    </div>
                </p>
                <h2 className="one">RETENTION OF DATA</h2>
                <p className="para">
                    Studybuddy2 LLC retains your personal data only for as long
                    as necessary to fulfill the purposes outlined in this
                    Privacy Policy. We may retain and use your data to meet
                    legal obligations, resolve disputes, and enforce our
                    agreements and policies. Usage Data, which is collected for
                    internal analysis, is generally kept for a shorter period.
                    However, it may be retained longer if needed to enhance
                    security, improve our service, or comply with legal
                    requirements.
                </p>
                <h2 className="one">TRANSFER OF DATA</h2>
                <p className="para">
                    Your personal information, including data, may be
                    transferred to and stored on servers located outside of your
                    state, province, country, or other governmental
                    jurisdiction. Data protection laws in these locations may
                    differ from those in your jurisdiction. By agreeing to this
                    Privacy Policy and submitting your information, you consent
                    to such transfers. Studybuddy2 LLC will take reasonable
                    measures to ensure your data is protected and handled in
                    accordance with this Privacy Policy. We will only transfer
                    your personal data to organizations or countries that
                    provide adequate protection and security for your
                    information.
                </p>
                <h2 className="one">DISCLOSURE FOR LAW ENFORCEMENT</h2>
                <p className="para">
                    Studybuddy2 LLC may disclose your personal data if required
                    to do so by law or in response to valid requests from public
                    authorities, such as a court or government agency.
                </p>
                <h2 className="one">LEGAL REQUIREMENTS</h2>
                <p className="para">
                    Studybuddy2 LLC may disclose your personal data when we
                    believe, in good faith, that such disclosure is necessary
                    to:
                    <br />
                    <br />
                    <strong>
                        <div className="para-list">
                            • Comply with legal obligations. <br />
                            • Protect and defend the rights or property of
                            Studybuddy2 LLC. <br />
                            • Investigate or prevent potential wrongdoing
                            related to our services.
                            <br />
                            • Ensure the personal safety of users or the public.
                            <br />• Address or prevent legal liability.
                        </div>
                    </strong>
                </p>
                <h2 className="one">SECURITY OF DATA</h2>
                <p className="para">
                    We prioritize the security of your data and use commercially
                    reasonable measures to protect it. However, please note that
                    no method of transmission over the Internet or electronic
                    storage is completely secure. While we strive to safeguard
                    your personal data, we cannot guarantee its absolute
                    security.
                </p>
                <p className="para">
                    <strong>Our Policy on "Do Not Track" Signals</strong>
                </p>
                <p className="para">
                    Under the California Online Protection Act (CalOPPA), we do
                    not support "Do Not Track" (DNT) signals. DNT is a web
                    browser setting that allows you to request not to be
                    tracked. You can enable or disable DNT through your
                    browser's preferences or settings.
                </p>
                <p className="para">
                    <strong>
                        Your Data Protection Rights under the General Data
                        Protection Regulation (GDPR)
                    </strong>
                </p>
                <p className="para">
                    If you are a resident of the European Economic Area (EEA),
                    you have certain rights regarding your personal data:
                </p>
                <p className="para">
                    <div className="para-list">
                        <strong>• Right to Access, Update, or Delete: </strong>{' '}
                        You can access, update, or request the deletion of your
                        personal data through your account settings. If you
                        cannot do this yourself, contact us for assistance.
                        <br />
                        <strong>• Right to Rectification: </strong> You have the
                        right to correct inaccurate or incomplete information.
                        <br />
                        <strong>• Right to Object: </strong> You may object to
                        our processing of your personal data.
                        <br />
                        <strong>• Right to Restriction: </strong>You can request
                        that we limit the processing of your data.
                        <br />
                        <strong>• Right to Data Portability: </strong> You are
                        entitled to receive your data in a structured,
                        machine-readable format.
                        <strong>• Right to Withdraw Consent: </strong> If we
                        rely on your consent for processing, you can withdraw it
                        at any time.
                    </div>
                </p>

                <p className="para">
                    Please note, we may require identity verification before
                    processing these requests. You also have the right to lodge
                    a complaint with a Data Protection Authority in the EEA. For
                    more details, contact your local authority.
                </p>

                <h2 className="one">SERVICE PROVIDERS</h2>
                <p className="para">
                    We may engage third-party companies and individuals to
                    perform services on our behalf, such as facilitating our
                    service, providing support, or analyzing service usage.
                    These service providers have access to your personal data
                    only to perform their tasks and are bound by confidentiality
                    agreements to prevent unauthorized use or disclosure.
                </p>

                <h2 className="one">CHILDREN’S PRIVACY</h2>
                <p className="para">
                    Our service is not intended for individuals under 18
                    ("Children"). We do not knowingly collect personal data from
                    children. If you are a parent or guardian and believe your
                    child has provided us with personal data, please contact us.
                    If we find that we have collected data from children without
                    parental consent, we will take steps to remove it.
                </p>

                <h2 className="one">CHANGES TO THIS PRIVACY POLICY</h2>
                <p className="para">
                    Our service is not intended for individuals under 18
                    ("Children"). We do not knowingly collect personal data from
                    children. If you are a parent or guardian and believe your
                    child has provided us with personal data, please contact us.
                    If we find that we have collected data from children without
                    parental consent, we will take steps to remove it.
                </p>

                <h2 className="one">CONTACT US</h2>
                <p className="para">
                    For any questions regarding this Privacy Policy, please
                    contact us at{' '}
                    <a href="https://studybuddy.gg/" style={{ color: 'blue' }}>
                        support@studybuddy.gg.
                    </a>
                </p>
            </div>
            <Footer />
        </div>
    );
};
