import React, { FC } from 'react';

import './CardItem.styles.scss';

type CardItemProps = {
    title: string;
    date: string;
    imgSrc: string;
    review: string;
};

export const CardItem: FC<CardItemProps> = (props) => {
    const { title, date, imgSrc, review } = props;
    return (
        <div className="cardItem">
            <img className="profile-image" src={imgSrc} alt="profile" />

            <div className="card-content">
                <div className="user-information">
                    <h3>{title}</h3>
                    <img src="/assets/icons/stars-black.svg" alt="stars" />
                    <h4>{date}</h4>
                </div>
                <p className="review-test">{review}</p>
            </div>
        </div>
    );
};
