export const defaultErrorMessage = 'Something went wrong loading the payment. Please refresh or contact support.';

export const paymentElementOptions: any = {
    layout: 'tabs',
    terms: {
        applePay: 'never',
        auBecsDebit: 'never',
        bancontact: 'never',
        card: 'never',
        cashapp: 'never',
        googlePay: 'never',
        ideal: 'never',
        paypal: 'never',
        sepaDebit: 'never',
        sofort: 'never',
        usBankAccount: 'never'
    },
    paymentMethodOrder: ['card', 'apple_pay', 'google_pay', 'cashapp', 'link'],
};