export const testimonialsData = [
    {
        name: 'Jennifer Hill',
        date: 'Apr 13, 2024',
        review: 'Jennifer_Hill_review',
        imgSrc: '/assets/images/testimonials/user-avater-1.png',
    },
    {
        name: 'Adiana Madrigal',
        date: 'Jan 7, 2024',
        review: 'Adiana_Madrigal_review',
        imgSrc: '/assets/images/testimonials/user-avater-2.png',
    },
    {
        name: 'Mark Atty',
        date: 'Feb 12, 2024',
        review: 'Mark_Atty_review',
        imgSrc: '/assets/images/testimonials/user-avater-3.png',
    },
    {
        name: 'Scott Moyer',
        date: 'Dec 17, 2023',
        review: 'I like that it actually gives explanations instead of just showing me the answers. It is super helpful for studying.',
        imgSrc: '/assets/images/testimonials/user-avater-4.png',
    },
    {
        name: 'Devyn Ford',
        date: 'Mar 28, 2024',
        review: 'Devyn_Ford_review',
        imgSrc: '/assets/images/testimonials/user-avater-5.png',
    },
    {
        name: 'Simon Sozzi',
        date: 'Apr 16, 2024',
        review: 'Simon_Sozzi_review',
        imgSrc: '/assets/images/testimonials/user-avater-6.png',
    },
    {
        name: 'Erik Cabanas',
        date: 'Apr 20, 2024',
        review: 'Erik_Cabanas_review',
        imgSrc: '/assets/images/testimonials/user-avater-7.png',
    },
    {
        name: 'Luna Castillo',
        date: 'Feb 22, 2024',
        review: 'Luna_Castillo_review',
        imgSrc: '/assets/images/testimonials/user-avater-8.png',
    },
    {
        name: 'AJ Bell',
        date: 'Mar 23, 2024',
        review: 'AJ_Bell_review',
        imgSrc: '/assets/images/testimonials/user-avater-9.png',
    },
];

export const testimonialsDesktopFirstColumn = testimonialsData.slice(0, 3);
export const testimonialsDesktopSecondColumn = testimonialsData.slice(3, 6);
export const testimonialsDesktopThirdColumn = testimonialsData.slice(6, 9);

export const testimonialsDesktopData = [
    testimonialsDesktopFirstColumn,
    testimonialsDesktopSecondColumn,
    testimonialsDesktopThirdColumn,
];

export const testimonialsMobileData = [
    testimonialsData[6],
    testimonialsData[5],
    testimonialsData[0],
    testimonialsData[1],
    testimonialsData[2],
    testimonialsData[4],
    testimonialsData[8],
];
