export enum AppRoutes {
    Home = '/',
    Dashboard = '/dashboard',
    DashboardContact = '/contact',
    Login = '/login',
    Register = '/register',
    Signin_Redirect = '/signin-redirect',
    Signout_Redirect = '/signout-redirect',
    ForgetPassword = '/forget-password',
    DashboardWelcomeVideo = '/dashboard?how-to-use=true',
    Privacy = '/privacy-policy',
    Terms = '/terms-conditions',
}

export enum Assets {
    googleSignInBtn = '/assets/images/sso-images/btn_google_signin.png',
    logo = '/assets/images/logo.png',
    workingDemo = '/assets/images/working-demo.png',
    demoImage = '/assets/images/demo-img.png',
    reviewsImage = '/assets/images/reviews.png',
    partnerLogoMoodle = '/assets/images/partnerLogos/moodle.png',
    partnerLogoCanvas = '/assets/images/partnerLogos/canvas.png',
    partnerLogoBlackboard = '/assets/images/partnerLogos/blackboard.png',
    partnerLogoTopHat = '/assets/images/partnerLogos/tophat.png',
    videoThumbnail = '/assets/images/video-thumbnail.png',
    pinExtensionImage = '/assets/images/pin_extension.png',
    onboardingStep1 = '/assets/images/onboarding_step_1.png',
    onboardingStep2 = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/new_onboarding_step_2.mp4',
    onboardingStep3 = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/new_onboarding_step_3.mp4',
    onboardingStep4 = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/new_onboarding_step_4.mp4',
    onboardingStep5 = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/new_onboarding_step_5.mp4',
    onboadingStepInstall = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/step1.mp4',
    onboadingStepPin = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/step2.mp4',
    onboadingStepHowToUse = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/onboarding_howtouse.mp4',
    onboadingStepSecretMode = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/onboarding_secretmode.mp4',
    onboardingExtensionTutorial = 'https://studybuddy-assets.s3.us-west-2.amazonaws.com/extension-tutorial-full.mp4',
    viralLoopBackground = '/assets/images/boy_space.jpg',
    vectorBackground = '/assets/images/lines_vector.svg',
    usersSvg = '/assets/images/users.svg',
    whatsAppLogo = '/assets/images/whatsapp.png',
    iMessageLogo = '/assets/images/imessage.png',
    copyIcon = '/assets/images/copy.png',
    telegramLogo = '/assets/images/telegramLogo.png',
    twitterLogo = '/assets/images/twitterLogo.png',
    /* registerSidePanel = '/assets/images/signupsidepanel.svg', */
    registerSidePanel = '/assets/images/side-panel-image.svg',
    shareModalImg = '/assets/images/peoples.svg',
    oliverSign = '/assets/images/oliver-sign.png',
    institutionHarvard = '/assets/images/institutions/harvard.png',
    institutionMIT = '/assets/images/institutions/mit.png',
    institutionStanford = '/assets/images/institutions/stanford.png',
    institutionBerkeley = '/assets/images/institutions/berkeley.png',
    institutionMcGill = '/assets/images/institutions/mcgill.png',
    institutionThc = '/assets/images/institutions/thc.png',
    ChatGPTLogo = '/assets/images/ChatGPTLogo.svg',
    ChatGPTLogoAlt = '/assets/images/ChatGPTLogo_Alt.svg',
    ChatGPTLogoBackground = '/assets/images/ChatGPTLogoBackground.svg',
    Brain = '/assets/images/Brain.svg',
    Target = '/assets/images/Target.svg',
    Neuron = '/assets/images/Neuron.svg',
    LightBulb = '/assets/images/LightBulb.svg',
    Button = '/assets/images/howItworks/Button.mp4',
    Floating = '/assets/images/howItworks/Floating.mp4',
    Screenshot = '/assets/images/howItworks/Screenshot.mp4',
    SafeCheckout = '/assets/images/safe-checkout.png',
    UserReview = '/assets/images/user-reviews.png',
    StudybuddyBlackLogo = '/assets/images/studybuddy_black_logo.png',
    userReviewMobile1 = "/assets/images/carousel/carousel_one.svg",
    userReviewMobile2 = "/assets/images/carousel/carousel_two.svg",
    userReviewMobile3 = "/assets/images/carousel/carousel_three.svg",
    userReviewMobile4 = "/assets/images/carousel/carousel_four.svg",
}

export enum icons {
    google = '/assets/icons/google.svg',
    apple = '/assets/icons/apple.svg',
    rightArrowWhite = '/assets/icons/rightArrowWhite.svg',
    downArrowWhite = '/assets/icons/downArrowWhite.svg',
    graduation = '/assets/icons/graduate-hat.svg',
    graduationBlack = '/assets/icons/graduate-hat-black.svg',
    home = '/assets/icons/home.svg',
    homeActive = '/assets/icons/home-active.svg',
    user = '/assets/icons/user.svg',
    userActive = '/assets/icons/user-active.svg',
    message = '/assets/icons/message.svg',
    messageActive = '/assets/icons/message-active.svg',
    affiliate = '/assets/icons/affiliate.svg',
    card = '/assets/icons/card.svg',
    copy = '/assets/icons/copy.svg',
    settings = '/assets/icons/settings.svg',
    search = '/assets/icons/search.svg',
    wave = '/assets/icons/wave.svg',
    logout = '/assets/icons/logout.svg',
    playbtn = '/assets/icons/play-btn.png',
    facebook = '/assets/icons/facebook.svg',
    instagram = '/assets/icons/instagram.svg',
    tictoc = '/assets/icons/tictoc.svg',
    youtube = '/assets/icons/youtube.svg',
    youtubeWhite = '/assets/icons/youtube-white.svg',
    rightUpArrow = '/assets/icons/arrow-up-right.svg',
    edit = '/assets/icons/edit.svg',
    email = '/assets/icons/email.svg',
    menu = '/assets/icons/menu.png',
    close = '/assets/icons/close.png',
    mastercard = '/assets/icons/mastercard.svg',
    visa = '/assets/icons/visa.svg',
    amex = '/assets/icons/amex.svg',
    discover = '/assets/icons/discover.png',
    gpay = '/assets/icons/gpay.svg',
    applepay = '/assets/icons/applepay.png',
    linkShare = '/assets/icons/linkShare.svg',
    pattern = '/assets/icons/pattern.svg',
    share = '/assets/icons/share.svg',
    rightUpArrowPurple = '/assets/icons/arrow-up-right-purple.svg',
    externalLink = '/assets/icons/external-link.svg',
    paypal = '/assets/icons/paypal.png',
    callSupport = '/assets/icons/call-support.svg',
    roundedCross = '/assets/icons/rounded-cross.svg',
    roundedStar = '/assets/icons/rounded-star.svg',
    disclaimer = '/assets/icons/disclaimer.svg',
    downloadBlue = '/assets/icons/download-blue.svg',
    rightAngleArrowWhite = '/assets/icons/right-angle-arrow-white.svg',
}

// NOTE: The extension relies on these cookie keys so all changes must be reflected in the extension
export enum CookieKeys {
    idTokenCookie = 'sb_id',
    accessTokenCookie = 'sb_at',
    refreshTokenCookie = 'sb_rt',
    accountStatus = 'sb_act',
    referralCookie = 'sb_rfid',
    userIdCookie = 'sb_usid',
    pricingDesignCookieKey = 'sb_pricing_design_id',
    weeklyPricingCookieKey = 'sb_updated_weekly_pricing_id',
    pricingTestCookieKey = 'sb_pricing_test_id',
    fbClickId = 'sb_fbclid',
}
