import React from 'react';
import { FAQItems } from './Faq.constants';
import { FaqItem } from './FaqItem/FaqItem';
import { useTranslation } from 'react-i18next';
import './Faq.styles.scss';

export const Faq = () => {
    const { t } = useTranslation();

    return (
        <div id="faq">
            <div id="faqTitle">
                {t('frequently_asked')} <span>{t('faq_quesions')}</span>
            </div>
            {/* <div id="faqTitleMobile">
                FA<span>Q</span>s
            </div> */}
            <div id="faqContainer">
                {FAQItems.map((item, index) => (
                    <FaqItem
                        key={index}
                        question={t(item.question)}
                        answer={t(item.answer)}
                        initState={index === 0}
                    />
                ))}
            </div>
            {/* <img id="gradientBG" src={gradientBGBlue} alt="bg" /> */}
            {/* <img
                id="gradientBGMobile"
                src={gradientBGBlueMobile}
                alt="bg_mobile"
            /> */}
        </div>
    );
};
