import React, { useState } from 'react';
import { Accordion, Container } from 'react-bootstrap';

import { CancelPlan, QAs } from './FAQ.constants';

import './FAQ.styles.scss';
import { useAuth, useSnackbar } from '~hooks';
import { getChurnkeyEnvironment, logger } from '~utils';
import { Loader } from '~components/Loader';
import { CK_ID } from '~constants';
import {
    getChurnkeyHash,
    submitCancellationEvents,
} from '~components/CancellationFlow/CancellationFlow.functions';
import { useTranslation } from 'react-i18next';

export const FAQ = () => {
    const { user, token, planStatus } = useAuth();
    const { errorSnackbar, infoSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const handleCancelPlan = async (e: any) => {
        e.preventDefault();

        setLoading(true);
        const customerId = user?.data?.attributes['custom:stripeCustomerID'];
        const email = user?.data?.attributes.email;

        getChurnkeyHash(token, customerId)
            .then((val) => {
                logger.debug('Received hash val: ', val);
                (window as any).churnkey.init('show', {
                    customerId: customerId,
                    authHash: val,
                    appId: CK_ID,
                    mode: getChurnkeyEnvironment(),
                    provider: 'stripe',
                    onClose: () => {
                        setLoading(true);
                        // Reload after 1 second to let all API calls complete
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    },
                    handleSupportRequest: () => {
                        infoSnackbar(
                            'Please email support@studybuddy.gg. I copied the email to your clipboard!',
                        );
                        navigator.clipboard.writeText('support@studybuddy.gg');
                        (window as any).churnkey.hide();
                    },
                    onCancel: () => {
                        submitCancellationEvents(email);
                    },
                    onError: () => {
                        errorSnackbar(
                            'Something went wrong while trying to cancel. Please email support@studybuddy.gg and let them know you received this error while trying to cancel.',
                        );
                    },
                });
            })
            .catch((e) => {
                errorSnackbar(
                    'Something went wrong while trying to cancel. Please email support@studybuddy.gg and let them know you received this error while trying to cancel.',
                    10000,
                );
                logger.error(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Container className="faq">
            {loading && <Loader />}
            <h1>{t('FAQs')}</h1>
            <Accordion defaultActiveKey="0">
                {QAs.map((qa, index) => (
                    <Accordion.Item
                        eventKey={index.toString()}
                        key={index}
                        bsPrefix="faq-item"
                    >
                        <Accordion.Header>{t(qa.question)}</Accordion.Header>
                        <Accordion.Body>{t(qa.answer)}</Accordion.Body>
                    </Accordion.Item>
                ))}
                <Accordion.Item
                    eventKey={QAs.length.toString()}
                    key={QAs.length}
                    bsPrefix="faq-item"
                >
                    <Accordion.Header>
                        {t(CancelPlan.question)}
                    </Accordion.Header>
                    <Accordion.Body>
                        {t(CancelPlan.answer)}{' '}
                        <p className="cancel-cta" onClick={handleCancelPlan}>
                            {t('cancel_by_clicking')}.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    );
};
