import React, { useEffect, useState } from 'react';

import { useAuth, useScreenSize } from '~hooks';
import {
    AppRoutes,
    CHROME_EXTENSION_DOWNLOAD_URL,
    CHROME_STORE_EXTENSION_URL,
    icons,
} from '~constants';
import { onboardingSteps } from './Onboarding.constants';
import { useExtensionHelper } from '~utils/extension';
import Confetti from '~components/Utility/Confetti';
import { Header } from '~components/Header';
import { OnboardingStep } from './OnboardingStep';

import './Onboarding.styles.scss';
import { useNavigate } from 'react-router-dom';

export type OnboardingProps = {
    showOnboarding: boolean;
    setShowOnboarding: (show: boolean) => void;
};

export const Onboarding: React.FC<OnboardingProps> = (
    props: OnboardingProps,
) => {
    const [step, setStep] = useState(1);
    const { isMobile, isTablet } = useScreenSize();
    const { sendAuthToExtension } = useAuth();

    const navigate = useNavigate();

    const handleLeftButtonClick = () => {
        if (step === 1 && !isMobile) {
            window.open(CHROME_EXTENSION_DOWNLOAD_URL);
            return;
        }
        setStep(step - 1);
    };

    const increaseStep = () => {
        setStep(step + 1);
    };

    const handleRightButtonClick = () => {
        props.setShowOnboarding(false);
        localStorage.setItem('isNewOnboardingCompleted', 'true');
        // Signal to retry the auth to the extension
        sendAuthToExtension();

        if (step === onboardingSteps.length + 1) {
            setStep(step + 1);
        }
        return;
    };

    const navigateToDashboard = () => {
        navigate(AppRoutes.Dashboard);
    };

    if (step === onboardingSteps.length && !props.showOnboarding) {
        return <Confetti />;
    }

    if (!props.showOnboarding) {
        return null;
    }

    return (
        <div className="onboarding-section">
            <div className="onboarding-page-logo" onClick={navigateToDashboard}>
                <img src={icons.graduationBlack} alt="logo" />
                <h3>
                    Study<span>buddy+</span>
                </h3>
            </div>
            {/* <div className="onboarding-header">
                <img src={icons.graduation} alt="logo" />
                <h3>
                    Study<span>buddy+</span>
                </h3>
            </div> */}
            <div className="onboarding-content">
                {(isMobile || isTablet) && (
                    <p className="mobile">
                        <strong>StudyBuddy</strong> isn't compatible with{' '}
                        <strong>iPad, iPhone, or Safari.</strong> It might have
                        issues with lockdown browsers and could be blocked on
                        some school networks. Please use a computer to{' '}
                        <strong>access StudyBuddy</strong>
                    </p>
                )}
                {/* <p
                            className={`steps-tab__step ${
                                onboardingStep.id === step ? 'active' : ''
                            }`}
                            key={onboardingStep.id}
                        >
                            Step {onboardingStep.id}
                        </p> */}

                <div className="steps-tab">
                    {onboardingSteps.map((onboardingStep) => (
                        <div className="step-container">
                            <div
                                className={`step-circle ${
                                    onboardingStep.id <= step ? 'active' : ''
                                }`}
                            >
                                <p>{onboardingStep.id}</p>
                            </div>
                            {!isMobile && <p>{onboardingStep.stepName}</p>}
                        </div>
                    ))}
                </div>
                <OnboardingStep
                    step={step}
                    increaseStep={increaseStep}
                    onLeftButtonClick={handleLeftButtonClick}
                    onRightButtonClick={handleRightButtonClick}
                />
            </div>
        </div>
    );
};
