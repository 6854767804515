import React from 'react';
import { CardItem } from './CardItem';
import { useScreenSize } from '~hooks';
import {
    testimonialsDesktopData,
    testimonialsMobileData,
} from './Testimonials.constants';

import './Testimonials.styles.scss';
import { useTranslation } from 'react-i18next';
import GoogleReview from '../../../assets/google-review.svg';

export const Testimonials = () => {
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();

    return (
        <div className="testimonials-section" id="testimonials-section">
            <h1 className="testimonials-title">
                {t('see_what_our')} <span>{t('customers')}</span> {t('say')}
            </h1>
            {!isMobile && (
                <div className="testimonial-cards">
                    {testimonialsDesktopData.map((testimonialColumn) => (
                        <div className="testimonial-column">
                            {testimonialColumn.map((card) => (
                                <CardItem
                                    title={card.name}
                                    imgSrc={card.imgSrc}
                                    date={card.date}
                                    review={t(card.review)}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            )}
            {isMobile && (
                <div className="testimonial-cards">
                    {testimonialsMobileData.map((card) => (
                        <CardItem
                            title={card.name}
                            imgSrc={card.imgSrc}
                            date={card.date}
                            review={t(card.review)}
                        />
                    ))}
                </div>
            )}

            <img
                className="google-reviews-logo"
                src={GoogleReview}
                alt="google review"
            />

            {/* <div className="curved-line">
                <img src="/assets/icons/curved-line.svg" alt="line" />
            </div> */}
        </div>
    );
};
