import React, { FC } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Assets } from '~constants';

import './SocialLogins.styles.scss';
import { GoogleBtn } from './GoogleBtn';

type SocialLoginsProps = {
    isGoogleSignup?: boolean;
};

export const SocialLogins: FC<SocialLoginsProps> = (props) => {
    const { isGoogleSignup } = props;

    const onGoogleLogin = () => {
        Auth.federatedSignIn({
            provider: CognitoHostedUIIdentityProvider.Google,
        });
    };

    return (
        <div className="social-logins">
            <div className="login-btn">
                <GoogleBtn
                    handleClick={onGoogleLogin}
                    isSignup={isGoogleSignup}
                />
            </div>
        </div>
    );
};
