import React, { FC, useEffect, useState } from 'react';
import './ReviewCarousel.styles.scss';

type ReviewCarouselProps = {
    userReviews: string[];
};

export const ReviewCarousel: FC<ReviewCarouselProps> = ({ userReviews }) => {
    const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % userReviews.length);
        }, 4000);

        return () => clearInterval(interval);
    }, [userReviews.length]);

    return (
        <div className="review-carousel-container">
            <div className="mobile-user-reviews-container">
                {userReviews.map((src, index) => (
                    <div
                        className={`mobile-review-item ${index === currentReviewIndex ? 'active' : ''}`}
                        key={index}
                    >
                        <img src={src} alt={`user review ${index + 1}`} />
                    </div>
                ))}
            </div>
            <div className="dots">
                {userReviews.map((_, index) => (
                    <div
                        key={index}
                        className={`dot ${index === currentReviewIndex ? 'active' : ''}`}
                    />
                ))}
            </div>
        </div>
    );
};