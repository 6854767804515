import React from 'react';

import { useScreenSize } from '~hooks';
import { UserInfo } from '~components/UserInfo';
import { PaymentPlan } from '~components/PaymentPlan';
import { BillingSummary } from '~components/BillingSummary';
import { Header } from '~components/Header';

import './DashboardProfile.styles.scss';
import { useTranslation } from 'react-i18next';

export const DashboardProfile = () => {
    const { isMobile } = useScreenSize();
    const { t } = useTranslation();

    return (
        <div className="dashboard-profile">
            {isMobile && <Header logoAlign="center" />}
            <h1 className="title">{t('profile_billing')}</h1>
            <div className="information-section">
                <UserInfo />
                <PaymentPlan />
            </div>
            <div className="summary-section">
                <BillingSummary />
            </div>
        </div>
    );
};
