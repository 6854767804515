export const QAs = [
    {
        question: 'dashboardFAQ1_question',
        answer: 'dashboardFAQ1_answer',
    },
    {
        question: 'dashboardFAQ2_question',
        answer: 'dashboardFAQ2_answer',
    },
    {
        question: 'dashboardFAQ3_question',
        answer: 'dashboardFAQ3_answer',
    },
    {
        question: 'dashboardFAQ4_question',
        answer: 'dashboardFAQ4_answer',
    },
    {
        question: 'dashboardFAQ5_question',
        answer: 'dashboardFAQ5_answer',
    },
    {
        question: 'dashboardFAQ6_question',
        answer: 'dashboardFAQ6_answer',
    },
    {
        question: 'dashboardFAQ7_question',
        answer: 'dashboardFAQ7_answer',
    },
];

export const CancelPlan = {
    question: 'dashboardCancel_question',
    answer: 'dashboardCancel_answer',
};
