import React from 'react';

import './Footer.styles.scss';
// import TiktokLogo from '../../../assets/TiktokLogo.svg';
import TiktokBlackLogo from '../../../assets/TiktokLogo_black.svg';
import StudybuddyLogoBlack from '../../../assets/studybuddy-logo-black.svg';
// import SocialsBlockLogo from '../../../assets/group-social-icons.svg';
// import InstaLogo from '../../../assets/InstaLogo.svg';
import InstaBlackLogo from '../../../assets/InstaLogo_black.svg';
import XBlackLogo from '../../../assets/XLogo_black.svg';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="footerContainer">
                <div className="footerTop">
                    <div className="footerBrand">
                        <a href="/">
                            {/* <img
                            src="/assets/images/newLogo.png"
                            alt="logo"
                            className="footerLogo"
                        /> */}
                            <img
                                src={StudybuddyLogoBlack}
                                alt="logo"
                                className="footerLogo"
                            />
                        </a>
                        <div className="socialsBlock">
                            {/* <a
                            // href="https://www.tiktok.com/@getstudybuddy.gg"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={SocialsBlockLogo} alt="socials-block" />
                        </a> */}
                            <a
                                href="https://www.tiktok.com/@getstudybuddy.gg"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={TiktokBlackLogo} alt="tiktok" />
                            </a>
                            <a
                                href="https://www.instagram.com/studybuddy.gg"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={InstaBlackLogo} alt="instagram" />
                            </a>

                            {/* <a
                            href="https://www.instagram.com/studybuddy.gg"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={XBlackLogo} alt="x" />
                        </a> */}
                        </div>
                        <div className="addressBlock">
                            <p className="addressText">
                                651 N Broad St, Suite 201, Middletown, 19709,
                                Delaware
                            </p>
                            <p className="addressText">
                                Contact email: support@studybuddy.gg
                            </p>
                        </div>
                    </div>
                    <div className="footerLinks">
                        <div>
                            <div className="footerListTitle">
                                {t('product')}
                            </div>
                            <ul className="footerList">
                                <li className="footerListItem">
                                    <a
                                        href="#pricing-section"
                                        className="footerLink"
                                    >
                                        {t('pricing')}
                                    </a>
                                </li>
                                <li className="footerListItem">
                                    <a href="#howItWorks" className="footerLink">
                                        {t('features')}
                                    </a>
                                </li>
                                <li className="footerListItem">
                                    <a
                                        href="https://chrome.google.com/webstore/detail/studybuddy%2B/ehaanimekcjndnhnkojcckjcgalkfgee/"
                                        className="footerLink"
                                    >
                                        {t('chrome_extension')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="footerListTitle">
                                {t('company')}
                            </div>
                            <ul className="footerList">
                                <li className="footerListItem">
                                    <a
                                        href="#testimonials-section"
                                        className="footerLink"
                                    >
                                        {t('reviews')}
                                    </a>
                                </li>
                                <li className="footerListItem">
                                    <a
                                        href="/privacy-policy"
                                        className="footerLink"
                                    >
                                        {t('privacy_policy')}
                                    </a>
                                </li>
                                <li className="footerListItem">
                                    <a
                                        href="/terms-conditions"
                                        className="footerLink"
                                    >
                                        {t('terms_of_service')}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className="footerListTitle">{t('help')}</div>
                            <ul className="footerList">
                                <li className="footerListItem">
                                    <a
                                        href="mailto:support@studybuddy.gg"
                                        className="footerLink"
                                    >
                                        {t('contact_us')}
                                    </a>
                                </li>
                                <li className="footerListItem">
                                    <a href="#faq" className="footerLink">
                                        FAQ's
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footerBottomContainer">
                    <hr className="footerHr" />
                    <small className="copyright">© Studybuddy 2024</small>
                </div>
            </div>
        </footer>
    );
};
