import { SignInData } from './Login.types';
import { logger } from '~utils';
import { Auth } from 'aws-amplify';

export const signIn = async (formData: SignInData) => {
    const { email, password } = formData;
    const lowerCaseEmail = email.toLocaleLowerCase();
    try {
        await Auth.signIn({
            username: lowerCaseEmail,
            password,
        });
    } catch (error: any) {
        logger.error(error);
        throw new Error(error.message);
    }
};

export const formValidation = (formData: SignInData) => {
    const { email, password } = formData;
    const errors = {
        email: '',
        password: '',
    };

    if (!email) {
        errors.email = 'Email is required';
    }
    if (!password) {
        errors.password = 'Password is required';
    }
    if (password && password.length < 6) {
        errors.password = 'Password must be at least 6 characters';
    }

    if (Object.values(errors).some((error) => error !== '')) {
        return errors;
    }
    return null;
};
