import { useEffect, useState } from 'react';
import {
    PaymentElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';

import { useAuth, useScreenSize, useSnackbar } from '~hooks';
import { AppRoutes, icons } from '~constants';
import {
    defaultErrorMessage,
    paymentElementOptions,
} from './checkoutForm.constants';
import { Loader } from '~components/Loader';

import './CheckoutForm.styles.scss';
import 'react-phone-number-input/style.css';

import PhoneInputWithCountrySelect, {
    isPossiblePhoneNumber,
} from 'react-phone-number-input';
import { postPaymentApi } from './CheckoutForm.functions';
import { logger } from '~utils';
import { useTranslation } from 'react-i18next';
import { getClientSecret } from '~pages/PaymentDetails/PaymentDetails.functions';

/* type CheckoutFormProps = {
    clientSecret: string;
}; */

export const CheckoutForm = () => {
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(true);
    const [phoneInput, setPhoneInput] = useState<any>();
    const { token } = useAuth();
    const { t } = useTranslation();

    const stripe = useStripe();
    const { errorSnackbar } = useSnackbar();
    const { isMobile } = useScreenSize();

    const isCtaDisabled = isLoading || !stripe;

    useEffect(() => {
        if (stripe != null && elements != null) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [elements, stripe]);

    const handleSubmit = async (e: any) => {
        // console.log('handleSubmit clientSecret ___', clientSecret);
        setIsLoading(true);
        e.preventDefault();
        if (!stripe || !elements || !token) {
            errorSnackbar(defaultErrorMessage);
            setIsLoading(false);
            return;
        }
        if (token && phoneInput && isPossiblePhoneNumber(phoneInput)) {
            console.log('phoneInput sending ___', phoneInput);
            postPaymentApi(token, {
                phone: phoneInput,
            });
        } else {
            logger.debug('Invalid token:', token);
        }

        const { error: submitError } = await elements.submit();
        if (submitError) {
            console.log('submitError ___', submitError);
            errorSnackbar(submitError?.message || defaultErrorMessage);
            return;
        }

        const paymentType = 'old-weekly-plan';

        const clientSecret = await getClientSecret(
            token,
            paymentType,
        );
        console.log('clientSecretCode and stPromise ___', clientSecret);

        const { error } = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
                return_url:
                    window.location.origin +
                    AppRoutes.Signin_Redirect +
                    '?payment_status=success',
            },
        });
        if (error?.decline_code === 'insufficient_funds') {
            errorSnackbar('Please use a card with a positive balance');
        } else {
            console.log('payment submit error ___', error);
            errorSnackbar(error?.message || defaultErrorMessage);
        }
        setIsLoading(false);
    };

    return (
        <div className="checkout-form">
            <form id="payment-form" onSubmit={handleSubmit}>
                <PaymentElement
                    id="payment-element"
                    options={paymentElementOptions}
                />
                {/* Phone */}
                <div className="phone-group">
                    <label htmlFor="phone">{t('phone')}</label>

                    <PhoneInputWithCountrySelect
                        placeholder="Enter phone number"
                        value={phoneInput}
                        onChange={setPhoneInput}
                        defaultCountry="US"
                        required
                    />
                </div>
                <button
                    disabled={isCtaDisabled}
                    className="cta-btn"
                    type="submit"
                >
                    {isLoading ? (
                        <Loader size="30px" color="#262626" />
                    ) : (
                        <span>{t('get_started_for_99_cent')}</span>
                    )}
                </button>
                <div className="payment-advantages">
                    <div className="advantage">
                        <img src={icons.roundedStar} alt="" />
                        <p>Satisfaction Guaranteed | </p>
                    </div>
                    {!isMobile && (
                        <div className="advantage">
                            <img src={icons.roundedCross} alt="" />
                            <p>Cancel Anytime | </p>
                        </div>
                    )}
                    <div className="advantage">
                        <img src={icons.callSupport} alt="" />
                        <p>24/7 Customer Support</p>
                    </div>
                </div>
                <p className="terms">
                    {t('terms_1')}
                    <br />
                    {t('terms_2')}{' '}
                    <a
                        href="https://studybuddy.gg/terms-conditions"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t('terms_of_service')}.
                    </a>
                </p>
            </form>
        </div>
    );
};
