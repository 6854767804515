import React, { useState } from 'react';

import { Assets, icons } from '~constants';
import { onboardingSteps } from '../Onboarding.constants';
import type { OnboardingStepProps } from './OnboardingStep.types';
import type { FC } from 'react';

import './OnboardingStep.styles.scss';
import { useScreenSize } from '~hooks';
import { TextParser } from '~components/TextParser';
import { FeedbackPopup } from '~components/Modals/FeedbackPopup';
import { TutorialWithButtonsPopup } from '~components/Modals/TutorialWithButtonsPopup';
import { SubmitQuesFormPopup } from '~components/Modals/SubmitQuesFormPopup';
import { parseTextWithStrongTag } from '~utils/parseDynamicText';

export const OnboardingStep: FC<OnboardingStepProps> = (props: any) => {
    const { isMobile, isTablet } = useScreenSize();
    const { step, onLeftButtonClick, onRightButtonClick, increaseStep } = props;
    console.log('🚀 ~ step:', step);

    const isShowExtenstionBtn = step === 1 && !isMobile && !isTablet;

    const isShowDoneBtn = step === onboardingSteps.length + 1;

    const isShowFeedbackPopup = step === 6;
    const isShowTutorialPopup = step === 7;
    const isShowFormPopup = step === 8;

    return (
        <div className="onboarding-steps-container">
            {onboardingSteps.map((onboardingStep) => {
                return (
                    <div
                        className={`onboarding-step ${
                            onboardingStep.id === step ? 'active' : ''
                        }`}
                        key={onboardingStep.id}
                    >
                        <h1>{onboardingStep.title}</h1>
                        {onboardingStep.bulletPoints && (
                            <ul>
                                {onboardingStep.bulletPoints.map((point) => (
                                    <li
                                        key={point}
                                        dangerouslySetInnerHTML={{
                                            __html: parseTextWithStrongTag(
                                                point,
                                            ),
                                        }}
                                    ></li>
                                ))}
                            </ul>
                        )}
                        {onboardingStep.subBulletPoints && (
                            <ul>
                                {onboardingStep.subBulletPoints.map(
                                    (subBulletPoint) => (
                                        <li key={subBulletPoint.title}>
                                            <strong>
                                                {subBulletPoint.title}
                                            </strong>
                                            <ul>
                                                {subBulletPoint.bulletPoints.map(
                                                    (point) => (
                                                        <li
                                                            key={point}
                                                            dangerouslySetInnerHTML={{
                                                                __html: parseTextWithStrongTag(
                                                                    point,
                                                                ),
                                                            }}
                                                        ></li>
                                                    ),
                                                )}
                                            </ul>
                                        </li>
                                    ),
                                )}
                            </ul>
                        )}

                        {/* <p>{onboardingStep.subTitle}</p> */}

                        <div className="onboarding-step__image">
                            {onboardingStep.assetType === 'video' && (
                                <video controls>
                                    <source src={onboardingStep.assetPath} />
                                </video>
                            )}
                            {onboardingStep.assetType === 'image' && (
                                <img src={onboardingStep.assetPath} />
                            )}
                        </div>
                    </div>
                );
            })}

            <div className="onboarding-buttons">
                {isShowExtenstionBtn ? (
                    <>
                        <button
                            className="cta-btn transparent"
                            onClick={onLeftButtonClick}
                        >
                            <img src={icons.downloadBlue} alt="open" />
                            Download Extension
                        </button>
                        <button className="cta-btn" onClick={increaseStep}>
                            {isShowDoneBtn ? (
                                <span>Done</span>
                            ) : (
                                <span>
                                    Next Step
                                    <img
                                        src={icons.rightAngleArrowWhite}
                                        alt="icon"
                                    />
                                </span>
                            )}
                        </button>
                    </>
                ) : (
                    <>
                        {!isMobile && !isTablet && (
                            <button
                                className="cta-btn transparent"
                                onClick={onLeftButtonClick}
                            >
                                <span>Back</span>
                            </button>
                        )}
                        <button className="cta-btn" onClick={increaseStep}>
                            {isShowDoneBtn ? (
                                <span>Done</span>
                            ) : (
                                <span>
                                    Next Step
                                    <img
                                        src={icons.rightAngleArrowWhite}
                                        alt="icon"
                                    />
                                </span>
                            )}
                        </button>
                    </>
                )}
            </div>

            {step === 6 && (
                <FeedbackPopup
                    step={step}
                    open={isShowFeedbackPopup}
                    onClose={() => {}}
                    increaseStep={increaseStep}
                    onLeftButtonClick={onLeftButtonClick}
                    onRightButtonClick={onRightButtonClick}
                />
            )}
            {step === 7 && (
                <TutorialWithButtonsPopup
                    step={step}
                    open={isShowTutorialPopup}
                    onClose={() => {}}
                    increaseStep={increaseStep}
                    onLeftButtonClick={onLeftButtonClick}
                    onRightButtonClick={onRightButtonClick}
                />
            )}
            {step === 8 && (
                <SubmitQuesFormPopup
                    step={step}
                    open={isShowFormPopup}
                    onClose={() => {}}
                    increaseStep={increaseStep}
                    onLeftButtonClick={onLeftButtonClick}
                    onRightButtonClick={onRightButtonClick}
                />
            )}
        </div>
    );
};
