import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { DisturbanceProps } from './DisturbancePopup.types';
import { Assets, icons } from '~constants';

import './DisturbancePopup.styles.scss';

export const DisturbancePopup: FC<DisturbanceProps> = (props) => {
    const { show, handleClose, handleCta, isDashboard } = props;

    const onPopupClickHandler = () => {
        handleCta();
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            contentClassName="modalContent"
        >
            <div className="disturbance-content">
                <div className="disturbance-body">
                    <img src={Assets.StudybuddyBlackLogo} alt="logo" />
                    <h1>StudyBuddy Update!</h1>
                    <p>
                        We're excited to announce that{' '}
                        <strong>StudyBuddy</strong> is back with a new extension
                        download process!
                    </p>
                    <p>
                        To install our extension, please ensure you are on a{' '}
                        <strong>Desktop, or Mac with Google Chrome.</strong>
                    </p>
                    <button
                        className="popup-cta-btn"
                        onClick={onPopupClickHandler}
                    >
                        Step-by-Step Instructions
                    </button>
                </div>
            </div>
        </Modal>
    );
};
