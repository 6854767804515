import type { Appearance } from '@stripe/stripe-js/types/stripe-js';

export const appearance: Appearance = {
    theme: 'flat',
    variables: {
        fontFamily: ' "Open Sans", sans-serif',
        fontLineHeight: '1.5',
        borderRadius: '10px',
        colorBackground: '#ffffff',
        colorPrimaryText: '#ffffff',
    },
    rules: {
        '.Block': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            padding: '12px',
        },
        '.Input': {
            backgroundColor: '#fff',
            border: '1px solid #EBEBEB',
            padding: '16px 20px',
            color: '#8D8D8D',
            fontSize: '14px',
            marginBottom: '8px',
            borderRadius: '10px',
        },
        '.Input:disabled, .Input--invalid:disabled': {
            color: 'lightgray',
        },
        '.Tab': {
            padding: '10px 12px',
            borderRadius: '10px',
        },
        '.Tab:hover': {
            border: 'none',
        },
        '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
            border: '1px solid #4F95FF',
            backgroundColor: '#3D5AF1',
            color: '#fff',
        },
        '.p-TabIcon--selected': {
            fill: '#fff',
        },
        '.Label': {
            fontWeight: '700',
            fontSize: '14px',
            color: '#0E153A',
            marginBottom: '12px',
        },
    },
};
