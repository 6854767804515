import React, { FC } from 'react';

import SupportBlue from '../../../assets/support-blue.svg';
import './TutorialWithButtonsPopup.styles.scss';
import { Assets } from '~constants';
// import { useNavigate } from 'react-router-dom';

type Props = {
    open: boolean;
    step: number;
    onClose: () => void;
    onLeftButtonClick: () => void;
    onRightButtonClick: () => void;
    increaseStep: () => void;
};

export const TutorialWithButtonsPopup: FC<Props> = (props) => {
    const { open, onClose, onRightButtonClick, increaseStep } = props;

    const handleClose = () => {
        onClose();
    };

    if (!open) {
        return null;
    }

    const navigateToDashboard = () => {
        onRightButtonClick();
        onClose();
        window.location.reload();
    };

    return (
        <div className="watchTutorial-popup">
            <div className="watchTutorial-content">
                <video
                    className="tutorial-video"
                    controls
                    autoPlay
                    muted={false}
                    loop={false}
                >
                    <source src={Assets.onboardingExtensionTutorial} />
                </video>

                <div className="buttons-container">
                    <button onClick={increaseStep} className="outlined">
                        <img
                            className="support-icon"
                            src={SupportBlue}
                            alt="support"
                        />{' '}
                        Got more questions?
                    </button>
                    <button onClick={navigateToDashboard} className="filled">
                        Done
                    </button>
                </div>
            </div>
            <div className="background-modal" onClick={handleClose}></div>
        </div>
    );
};
