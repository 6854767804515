import React from 'react';

import { Header } from '~pages/Homepage/Header';
import { Footer } from '~pages/Homepage/Footer';

import './Terms.styles.scss';

export const Terms = () => {
    return (
        <div className="terms-page">
            <Header />
            <div className="legals">
                <h1>Terms of Service</h1>
                <br></br>
                <h2 className="dates">Updated: August 02, 2024</h2>
                <br></br>
                <h2 className="one">AGREEMENT TO OUR LEGAL TERMS</h2>
                <p className="para">
                    {' '}
                    We are Studybuddy2 LLC ("Company," "we," "us," "our"), a
                    company operating the website{' '}
                    <a href="https://studybuddy.gg/" style={{ color: 'blue' }}>
                        https://studybuddy.gg/
                    </a>{' '}
                    (the "Site"), as well as any other related products and
                    services that refer or link to these legal terms (the "Legal
                    Terms") (collectively, the "Services").
                    <br />
                    <br />
                    You can contact us by email at{' '}
                    <a href="support@studybuddy.gg." style={{ color: 'blue' }}>
                        support@studybuddy.gg.
                    </a>
                    <br />
                    <br />
                    These Legal Terms constitute a legally binding agreement
                    made between you, whether personally or on behalf of an
                    entity ("you"), and Studybuddy2 LLC, concerning your access
                    to and use of the Services. By accessing the Services, you
                    agree that you have read, understood, and agreed to be bound
                    by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF
                    THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM
                    USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                    <br />
                    <br />
                    Supplemental terms and conditions or documents that may be
                    posted on the Services from time to time are hereby
                    expressly incorporated herein by reference. We reserve the
                    right, in our sole discretion, to make changes or
                    modifications to these Legal Terms from time to time. We
                    will alert you about any changes by updating the "Last
                    updated" date of these Legal Terms, and you waive any right
                    to receive specific notice of each such change. It is your
                    responsibility to periodically review these Legal Terms to
                    stay informed of updates. You will be subject to, and will
                    be deemed to have been made aware of and to have accepted,
                    the changes in any revised Legal Terms by your continued use
                    of the Services after the date such revised Legal Terms are
                    posted.
                    <br />
                    <br />
                    The Services are intended for users who are at least 13
                    years of age. All users who are minors in the jurisdiction
                    in which they reside (generally under the age of 18) must
                    have the permission of, and be directly supervised by, their
                    parent or guardian to use the Services. If you are a minor,
                    you must have your parent or guardian read and agree to
                    these Legal Terms prior to using the Services.
                    <br />
                    <br />
                    Studybuddy is designed to assist you in studying and
                    understanding educational material. It is not intended for
                    cheating or to be used on any exams, tests, or graded
                    assignments. The user takes full responsibility for the
                    appropriate use of this tool. Please use Studybuddy
                    ethically and responsibly.
                    <br />
                    <br />
                    Before using the Studybuddy Extension with your Learning
                    Management System (LMS), please be aware that its use may be
                    governed by specific policies and guidelines established by
                    your educational institution or LMS provider. Make sure to
                    comply with these regulations to avoid any potential issues.
                    <br />
                    <br />
                </p>
                <h2 className="exp">
                    We recommend that you print a copy of these Legal Terms for
                    your records.
                </h2>
                <br />
                <h2 className="content">TABLE OF CONTENTS</h2>

                <p className="contents">
                    1.OUR SERVICES
                    <br />
                    2.INTELLECTUAL PROPERTY RIGHTS
                    <br />
                    3.USER REPRESENTATIONS
                    <br />
                    4.USER REGISTRATION
                    <br />
                    5.PURCHASES AND PAYMENTS
                    <br />
                    6.SUBSCRIPTIONS
                    <br />
                    7.PROHIBITED ACTIVITIES
                    <br />
                    8.CONTRIBUTION LICENSE
                    <br />
                    9.THIRD-PARTY WEBSITES AND CONTENT
                    <br />
                    10.SERVICES MANAGEMENT
                    <br />
                    11.PRIVACY POLICY
                    <br />
                    12.TERM AND TERMINATION
                    <br />
                    13.MODIFICATIONS AND INTERRUPTIONS
                    <br />
                    14.GOVERNING LAW
                    <br />
                    15.DISPUTE RESOLUTION
                    <br />
                    16.CORRECTIONS
                    <br />
                    17.DISCLAIMER
                    <br />
                    18.LIMITATIONS OF LIABILITY
                    <br />
                    19.INDEMNIFICATION
                    <br />
                    20.ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                    <br />
                    21.CALIFORNIA USERS AND RESIDENTS
                    <br />
                    22.MISCELLANEOUS
                    <br />
                    23.CHANGE TO TERMS
                    <br />
                    24.CONTACT US
                    <br />
                    25.MOBILE TERMS OF SERVICE
                    <br />
                </p>
                <h2 className="one">1. OUR SERVICES</h2>
                <p className="para">
                    The information provided when using the Services is not
                    intended for distribution to or use by any person or entity
                    in any jurisdiction or country where such distribution or
                    use would be contrary to law or regulation or which would
                    subject us to any registration requirement within such
                    jurisdiction or country. Accordingly, those persons who
                    choose to access the Services from other locations do so on
                    their own initiative and are solely responsible for
                    compliance with local laws, if and to the extent local laws
                    are applicable.
                    <br />
                    <br />
                    The Services are not tailored to comply with
                    industry-specific regulations (Health Insurance Portability
                    and Accountability Act (HIPAA), Federal Information Security
                    Management Act (FISMA), etc.), so if your interactions would
                    be subjected to such laws, you may not use the Services. You
                    may not use the Services in a way that would violate the
                    Gramm-Leach-Bliley Act (GLBA).
                </p>
                <br />
                <h2 className="one">2. INTELLECTUAL PROPERTY RIGHTS</h2>
                <p className="two">Our Intellectual Property</p>
                <p className="para">
                    We are the owner or the licensee of all intellectual
                    property rights in our Services, including all source code,
                    databases, functionality, software, website designs, audio,
                    video, text, photographs, and graphics in the Services
                    (collectively, the "Content"), as well as the trademarks,
                    service marks, and logos contained therein (the "Marks").
                    <br /> <br />
                    Our Content and Marks are protected by copyright and
                    trademark laws (and various other intellectual property
                    rights and unfair competition laws) and treaties in the
                    United States and around the world.
                    <br /> <br />
                    The Content and Marks are provided in or through the
                    Services "AS IS" for your personal, non-commercial use or
                    internal business purpose only.
                </p>
                <p className="two">Your Use of Our Services</p>
                <p className="para">
                    Subject to your compliance with these Legal Terms, including
                    the "PROHIBITED ACTIVITIES" section below, we grant you a
                    non-exclusive, non-transferable, revocable license to:
                    <br />
                    <br />
                    • access the Services; and
                    <br />
                    • download or print a copy of any portion of the Content to
                    which you have properly gained access,
                    <br />
                    <br />
                    solely for your personal, non-commercial use or internal
                    business purpose.
                    <br />
                    <br />
                    Except as set out in this section or elsewhere in our Legal
                    Terms, no part of the Services and no Content or Marks may
                    be copied, reproduced, aggregated, republished, uploaded,
                    posted, publicly displayed, encoded, translated,
                    transmitted, distributed, sold, licensed, or otherwise
                    exploited for any commercial purpose whatsoever, without our
                    express prior written permission.
                    <br />
                    <br />
                    If you wish to make any use of the Services, Content, or
                    Marks other than as set out in this section or elsewhere in
                    our Legal Terms, please address your request to:
                    support@studybuddy.gg. If we ever grant you the permission
                    to post, reproduce, or publicly display any part of our
                    Services or Content, you must identify us as the owners or
                    licensors of the Services, Content, or Marks and ensure that
                    any copyright or proprietary notice appears or is visible on
                    posting, reproducing, or displaying our Content.
                    <br />
                    <br />
                    We reserve all rights not expressly granted to you in and to
                    the Services, Content, and Marks.
                    <br />
                    <br />
                    Any breach of these Intellectual Property Rights will
                    constitute a material breach of our Legal Terms and your
                    right to use our Services will terminate immediately.
                </p>
                <p className="two">Your Submissions</p>
                <p className="para">
                    Please review this section and the "PROHIBITED ACTIVITIES"
                    section carefully prior to using our Services to understand
                    the (a) rights you give us and (b) obligations you have when
                    you post or upload any content through the Services.
                </p>
                <p className="para">
                    <strong>Submissions:</strong> By directly sending us any
                    question, comment, suggestion, idea, feedback, or other
                    information about the Services ("Submissions"), you agree to
                    assign to us all intellectual property rights in such
                    Submission. You agree that we shall own this Submission and
                    be entitled to its unrestricted use and dissemination for
                    any lawful purpose, commercial or otherwise, without
                    acknowledgment or compensation to you.
                </p>
                <p className="para">
                    <strong>
                        You are responsible for what you post or upload:
                    </strong>
                    By sending us Submissions through any part of the Services
                    you:
                    <br />
                    <div className="para-list">
                        <span>
                            • confirm that you have read and agree with our
                            "PROHIBITED ACTIVITIES" and will not post, send,
                            publish, upload, or transmit through the Services
                            any Submission that is illegal, harassing, hateful,
                            harmful, defamatory, obscene, bullying, abusive,
                            discriminatory, threatening to any person or group,
                            sexually explicit, false, inaccurate, deceitful, or
                            misleading;
                        </span>
                        <br />
                        <span>
                            • to the extent permissible by applicable law, waive
                            any and all moral rights to any such Submission;
                        </span>
                        <br />
                        <span>
                            • warrant that any such Submission is original to
                            you or that you have the necessary rights and
                            licenses to submit such Submissions and that you
                            have full authority to grant us the above-mentioned
                            rights in relation to your Submissions; and
                        </span>
                        <br />
                        <span>
                            • warrant and represent that your Submissions do not
                            constitute confidential information.
                        </span>
                    </div>
                </p>
                <p className="para">
                    You are solely responsible for your Submissions and you
                    expressly agree to reimburse us for any and all losses that
                    we may suffer because of your breach of (a) this section,
                    (b) any third party's intellectual property rights, or (c)
                    applicable law.
                </p>
                <h2 className="one">3. USER REPRESENTATIONS</h2>
                <p className="para">
                    By using the Services, you represent and warrant that: (1)
                    all registration information you submit will be true,
                    accurate, current, and complete; (2) you will maintain the
                    accuracy of such information and promptly update such
                    registration information as necessary; (3) you have the
                    legal capacity and you agree to comply with these Legal
                    Terms; (4) you are not under the age of 13; (5) you are not
                    a minor in the jurisdiction in which you reside, or if a
                    minor, you have received parental permission to use the
                    Services; (6) you will not access the Services through
                    automated or non-human means, whether through a bot, script
                    or otherwise; (7) you will not use the Services for any
                    illegal or unauthorized purpose; and (8) your use of the
                    Services will not violate any applicable law or regulation.
                    <br />
                    <br />
                    If you provide any information that is untrue, inaccurate,
                    not current, or incomplete, we have the right to suspend or
                    terminate your account and refuse any and all current or
                    future use of the Services (or any portion thereof).
                </p>
                <h2 className="one">4. USER REGISTRATION</h2>
                <p className="para">
                    You may be required to register to use the Services. You
                    agree to keep your password confidential and will be
                    responsible for all use of your account and password. We
                    reserve the right to remove, reclaim, or change a username
                    you select if we determine, in our sole discretion, that
                    such username is inappropriate, obscene, or otherwise
                    objectionable.
                </p>
                <h2 className="one">5. PURCHASES AND PAYMENTS </h2>
                <p className="para">
                    We may provide paid products and/or services within the
                    Service.
                    <br />
                    <br />
                    You agree to provide current, complete, and accurate
                    purchase and account information for all purchases made via
                    the Services. You further agree to promptly update account
                    and payment information, including email address, payment
                    method, and payment card expiration date, so that we can
                    complete your transactions and contact you as needed. Sales
                    tax will be added to the price of purchases as deemed
                    required by us. We may change prices at any time. All
                    payments shall be in US dollars or the equivalent exchange
                    rate.
                    <br />
                    <br />
                    You agree to pay all charges at the prices then in effect
                    for your purchases and any applicable shipping fees, and you
                    authorize us to charge your chosen payment provider for any
                    such amounts upon placing your order. We reserve the right
                    to correct any errors or mistakes in pricing, even if we
                    have already requested or received payment.
                    <br />
                    <br />
                    We use third-party services for payment processing (e.g.,
                    payment processors). We will not store or collect your
                    payment card details. That information is provided directly
                    to our third-party payment processors whose use of your
                    personal information is governed by their Privacy Policy.
                    These payment processors adhere to the standards set by
                    PCI-DSS as managed by the PCI Security Standards Council,
                    which is a joint effort of brands like Visa, MasterCard,
                    American Express, and Discover. PCI-DSS requirements help
                    ensure the secure handling of payment information. The
                    payment processors we work with are:
                    <br />
                    <br />
                    <span className="para-list">
                        • <strong>Stripe:</strong> Their Privacy Policy can be
                        viewed at https://stripe.com/us/privacy
                    </span>
                    <br />
                    <br />
                    We reserve the right to refuse any order placed through the
                    Services. We may, in our sole discretion, limit or cancel
                    quantities purchased per person, per household, or per
                    order. These restrictions may include orders placed by or
                    under the same customer account, the same payment method,
                    and/or orders that use the same billing or shipping address.
                    We reserve the right to limit or prohibit orders that, in
                    our sole judgment, appear to be placed by dealers,
                    resellers, or distributors.
                </p>
                <h2 className="one">6. SUBSCRIPTIONS</h2>
                <p>
                    <p className="two">Billing and Renewal</p>
                    <p className="para">
                        Subscriptions renew automatically unless canceled. By
                        subscribing, you consent to recurring charges without
                        prior approval for each charge, until cancellation. The
                        billing cycle depends on the subscription plan.
                    </p>

                    <p className="two">Cancellation and Refund Policy</p>

                    <p className="para">
                        We have a no-questions-asked refund policy. If you're
                        not satisfied with Studybuddy, just send us an email at
                        <a
                            href="https://studybuddy.gg/"
                            style={{ color: 'blue' }}
                        >
                            https://studybuddy.gg/
                        </a>
                        , and we'll promptly refund your payment or cancel your
                        subscription. Your satisfaction is our priority.
                    </p>

                    <p className="two">Fee Changes</p>

                    <p className="para">
                        Subscription fees may change, and we will notify you as
                        required by law.
                    </p>
                </p>
                <h2 className="one">8. CONTRIBUTION LICENSE</h2>
                <p className="para">
                    By submitting feedback, ideas, or suggestions
                    ("Contributions"), you grant us a perpetual, worldwide,
                    royalty-free, irrevocable, and sublicensable license to use,
                    reproduce, modify, and distribute such Contributions for any
                    purpose without compensation or acknowledgment.
                </p>
                <h2 className="one">9. THIRD-PARTY WEBSITES AND CONTENT</h2>
                <p className="para">
                    Our Services may include links to external websites
                    ("Third-Party Websites") or provide access to articles,
                    photographs, text, graphics, designs, music, sound, video,
                    applications, software, and other content originating from
                    third parties ("Third-Party Content"). Please be aware that
                    we do not investigate, monitor, or verify the accuracy,
                    appropriateness, or completeness of Third-Party Websites or
                    Third-Party Content.
                    <br />
                    <br />
                    We are not responsible for any Third-Party Websites accessed
                    through our Services or any Third-Party Content posted on,
                    available through, or installed from our Services. This
                    includes, but is not limited to, the content, accuracy,
                    legality, opinions, reliability, privacy practices, or other
                    policies associated with Third-Party Websites or Third-Party
                    Content. The inclusion of links or access to Third-Party
                    Websites or Third-Party Content does not imply endorsement
                    or approval by us.
                    <br />
                    <br />
                    If you choose to leave our Services to visit Third-Party
                    Websites or to use or install Third-Party Content, you do so
                    at your own risk. Our Terms do not apply to Third-Party
                    Websites or Third-Party Content, and you should review the
                    applicable terms, policies, and privacy practices of any
                    third party you interact with.
                    <br />
                    <br />
                    Any transactions or purchases made through Third-Party
                    Websites are solely between you and the third party. We are
                    not responsible for any issues arising from such
                    transactions and disclaim all responsibility for the
                    products or services offered on Third-Party Websites. You
                    agree to hold us harmless from any harm or losses resulting
                    from your interaction with Third-Party Websites or
                    Third-Party Content.
                </p>
                <h2 className="one">10. SERVICES MANAGEMENT</h2>
                <p className="para">
                    We reserve the right to manage and monitor StudyBuddy to
                    ensure compliance with these Legal Terms. This includes the
                    right to:
                    <br />
                    <br />
                    <span className="para-list">
                        • Remove or edit any content that violates these terms
                        or is otherwise objectionable.
                    </span>
                    <br />
                    <span className="para-list">
                        • Suspend or terminate user accounts for violations or
                        unauthorized activities.
                    </span>
                </p>
                <h2 className="one">11. PRIVACY POLICY</h2>
                <p className="para">
                    At Studybuddy, we are committed to protecting your data
                    privacy and security. For detailed information on how we
                    handle your data, please review our Privacy Policy at{' '}
                    <a href="https://studybuddy.gg/" style={{ color: 'blue' }}>
                        https://studybuddy.gg/privacy-policy.
                    </a>
                    By using our Services, you agree to the terms set forth in
                    our Privacy Policy, which forms part of these Legal Terms.
                    <br />
                    <br />
                    Please be aware that our Services are hosted in the United
                    States. If you access our Services from outside the U.S.,
                    with data protection laws that differ from those in the
                    United States, you are consenting to the transfer of your
                    data to the U.S. and to the processing of your data
                    according to U.S. laws by continuing to use our Services.
                    <br />
                    <br />
                    We do not knowingly collect or solicit information from
                    individuals under the age of 13, nor do we target marketing
                    towards them. In line with the U.S. Children’s Online
                    Privacy Protection Act (COPPA), if we discover that we have
                    collected personal information from a child under 13 without
                    verifiable parental consent, we will promptly delete such
                    information from our records.
                </p>
                <h2 className="one">12. TERM AND TERMINATION</h2>
                <p className="para">
                    These Terms and Conditions shall remain in effect while you
                    use the Services provided by Studybuddy2 LLC. We reserve the
                    right to, at our sole discretion and without prior notice or
                    liability, deny access to and use of the Services to any
                    individual for any reason or no reason at all. This
                    includes, but is not limited to, instances of breach of any
                    representation, warranty, or covenant contained in these
                    Terms and Conditions, or violations of any applicable laws
                    or regulations. We may terminate your use or participation
                    in the Services or delete your account and any content or
                    information you have posted at any time, without warning and
                    at our sole discretion.
                    <br />
                    <br />
                    If we terminate or suspend your account for any reason, you
                    are prohibited from registering and creating a new account
                    under your name, a fictitious name, or the name of any third
                    party, even if acting on behalf of the third party. In
                    addition to terminating or suspending your account, we
                    reserve the right to pursue appropriate legal action,
                    including civil, criminal, and injunctive relief.
                </p>
                <h2 className="one">13. MODIFICATIONS AND INTERRUPTIONS</h2>
                <p className="para">
                    Studybuddy2 LLC reserves the right to change, modify, or
                    remove any aspect of the Services at our sole discretion and
                    without prior notice. This includes any content, features,
                    or functionalities of the Services. We are not obligated to
                    update any information on our Services, and we shall not be
                    liable to you or any third party for any modifications,
                    price changes, suspensions, or discontinuations of the
                    Services.
                    <br />
                    <br /> The availability of the Services is not guaranteed at
                    all times. We may encounter issues related to hardware,
                    software, or other factors, or may need to perform
                    maintenance, which could result in interruptions, delays, or
                    errors. Studybuddy2 LLC reserves the right to revise,
                    update, suspend, discontinue, or otherwise modify the
                    Services at any time or for any reason without notice to
                    you. You agree that we shall not be liable for any loss,
                    damage, or inconvenience caused by your inability to access
                    or use the Services during any downtime or discontinuation.
                    Nothing in these Terms and Conditions obligates us to
                    maintain or support the Services or to provide any updates,
                    corrections, or releases related to them.
                </p>
                <h2 className="one">14. GOVERNING LAW</h2>
                <p className="para">
                    These Terms and Conditions, and your use of the Services,
                    are governed by and construed in accordance with the laws of
                    the State of Delaware, without regard to its conflict of law
                    principles. Any disputes arising out of or related to these
                    Terms and Conditions or the Services shall be resolved in
                    accordance with the laws of the State of Delaware.
                </p>
                <h2 className="one">15. DISPUTE RESOLUTION</h2>
                <p className="two">Informal Negotiations</p>
                <p className="para">
                    To resolve any dispute, controversy, or claim related to
                    these Terms and Conditions (each a "Dispute" and
                    collectively, the "Disputes") brought by either you or us
                    (individually, a "Party" and collectively, the "Parties"),
                    the Parties agree to first attempt to negotiate any Dispute
                    informally for at least thirty (30) days before initiating
                    formal proceedings. Informal negotiations will commence upon
                    written notice from one Party to the other Party.
                </p>
                <p className="two">Binding Arbitration</p>
                <p className="para">
                    If the Parties cannot resolve a Dispute through informal
                    negotiations, the Dispute (except those specifically
                    excluded below) will be resolved exclusively through binding
                    arbitration. You understand that by agreeing to arbitration,
                    you are waiving your right to a court trial and a jury
                    trial. The arbitration will be conducted under the
                    Commercial Arbitration Rules of the American Arbitration
                    Association ("AAA") and, if applicable, the AAA's
                    Supplementary Procedures for Consumer Related Disputes ("AAA
                    Consumer Rules"), which are available on the AAA website.
                    The arbitration may be conducted in person, by phone,
                    online, or through the submission of documents. The
                    arbitrator will issue a written decision but is not required
                    to provide a detailed explanation unless requested by either
                    Party. The arbitrator must adhere to applicable law, and any
                    award may be challenged on the grounds of legal error.
                    Arbitration will take place in Delaware. The Parties may
                    seek court intervention to compel arbitration, stay
                    proceedings pending arbitration, or to confirm, modify,
                    vacate, or enter judgment on the arbitration award.
                </p>
                <h2 className="two">Court Proceedings</h2>
                <p className="para">
                    If for any reason a Dispute proceeds in court rather than
                    through arbitration, it shall be filed in the state and
                    federal courts located in Delaware. The Parties consent to
                    the jurisdiction of these courts and waive any defenses
                    related to personal jurisdiction or venue. The application
                    of the United Nations Convention on Contracts for the
                    International Sale of Goods and the Uniform Computer
                    Information Transaction Act (UCITA) is excluded from these
                    Terms and Conditions.
                </p>
                <h2 className="two">Restrictions</h2>
                <p className="para">
                    Arbitration shall be limited to the individual Dispute
                    between the Parties. To the fullest extent permitted by law,
                    (a) no arbitration shall be joined with any other
                    proceeding; (b) there is no right to arbitration on a
                    class-action basis or to utilize class action procedures;
                    and (c) no Dispute may be brought in a representative
                    capacity on behalf of the general public or other persons.
                </p>
                <h2 className="two">
                    Exceptions to Informal Negotiations and Arbitration
                </h2>
                <p className="para">
                    The following Disputes are not subject to the informal
                    negotiations or arbitration provisions: (a) any Disputes
                    concerning the enforcement or protection of intellectual
                    property rights; (b) any Dispute related to allegations of
                    theft, piracy, invasion of privacy, or unauthorized use; and
                    (c) any claim seeking injunctive relief. If any part of this
                    provision is deemed illegal or unenforceable, that part will
                    not apply, and the remaining Disputes will be decided by a
                    court of competent jurisdiction within the courts specified
                    above, with the Parties agreeing to submit to that court’s
                    jurisdiction.
                </p>
                <h2 className="one">16. CORRECTIONS</h2>
                <p className="para">
                    The Studybuddy Services may contain typographical errors,
                    inaccuracies, or omissions, including descriptions, pricing,
                    availability, and various other information. We reserve the
                    right to correct any errors, inaccuracies, or omissions and
                    to change or update the information on the Studybuddy
                    Services at any time, without prior notice.
                </p>
                <h2 className="one">17. DISCLAIMER</h2>
                <p className="para">
                    THE STUDYBUDDY SERVICES ARE PROVIDED ON AN AS-IS AND
                    AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES
                    WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED
                    BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN
                    CONNECTION WITH THE SERVICES AND YOUR USE THEREOF,
                    INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                    NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS
                    ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT
                    OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED
                    TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR
                    RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES
                    OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
                    DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS
                    TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO
                    OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                    INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4)
                    ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
                    SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
                    WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY
                    THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY
                    CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND
                    INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
                    TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES.
                    WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                    RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                    OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
                    HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
                    FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT
                    BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
                    ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF
                    PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR
                    SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
                    USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
                    APPROPRIATE.
                </p>
                <h2 className="one">18. LIMITATIONS OF LIABILITY</h2>
                <p className="para">
                    IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS
                    BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
                    INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
                    PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS
                    OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE
                    SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
                    SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY
                    CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
                    WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT
                    ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF
                    ANY, BY YOU TO US DURING THE THREE (3) MONTH PERIOD PRIOR TO
                    ANY CAUSE OF ACTION ARISING OR $144.00 USD. CERTAIN US STATE
                    LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON
                    IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
                    DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
                    ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
                    YOU MAY HAVE ADDITIONAL RIGHTS.
                </p>
                <h2 className="one">19. INDEMNIFICATION</h2>
                <p className="para">
                    You agree to defend, indemnify, and hold us harmless,
                    including our subsidiaries, affiliates, and all of our
                    respective officers, agents, partners, and employees, from
                    and against any loss, damage, liability, claim, or demand,
                    including reasonable attorneys' fees and expenses, made by
                    any third party due to or arising out of: (1) use of the
                    Studybuddy Services; (2) breach of these Legal Terms; (3)
                    any breach of your representations and warranties set forth
                    in these Legal Terms; (4) your violation of the rights of a
                    third party, including but not limited to intellectual
                    property rights; or (5) any overt harmful act toward any
                    other user of the Studybuddy Services with whom you
                    connected via the Services. Notwithstanding the foregoing,
                    we reserve the right, at your expense, to assume the
                    exclusive defense and control of any matter for which you
                    are required to indemnify us, and you agree to cooperate, at
                    your expense, with our defense of such claims. We will use
                    reasonable efforts to notify you of any such claim, action,
                    or proceeding which is subject to this indemnification upon
                    becoming aware of it.
                </p>
                <h2 className="one">
                    20. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                </h2>
                <p className="para">
                    Visiting the Studybuddy Services, sending us emails, and
                    completing online forms constitute electronic
                    communications. You consent to receive electronic
                    communications, and you agree that all agreements, notices,
                    disclosures, and other communications we provide to you
                    electronically, via email and on the Services, satisfy any
                    legal requirement that such communication be in writing. YOU
                    HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS,
                    ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
                    NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR
                    COMPLETED BY US OR VIA THE SERVICES. You hereby waive any
                    rights or requirements under any statutes, regulations,
                    rules, ordinances, or other laws in any jurisdiction which
                    require an original signature or delivery or retention of
                    non-electronic records, or to payments or the granting of
                    credits by any means other than electronic means.
                </p>
                <h2 className="one">21. CALIFORNIA USERS AND RESIDENTS</h2>
                <p className="para">
                    If any complaint with us is not satisfactorily resolved, you
                    can contact the Complaint Assistance Unit of the Division of
                    Consumer Services of the California Department of Consumer
                    Affairs in writing at 1625 North Market Blvd., Suite N 112,
                    Sacramento, California 95834 or by telephone at (800)
                    952-5210 or (916) 445-1254.
                </p>
                <h2 className="one">22. MISCELLANEOUS</h2>
                <p className="para">
                    These Legal Terms and any policies or operating rules posted
                    by us on the Studybuddy Services or in respect to the
                    Services constitute the entire agreement and understanding
                    between you and us. Our failure to exercise or enforce any
                    right or provision of these Legal Terms shall not operate as
                    a waiver of such right or provision. These Legal Terms
                    operate to the fullest extent permissible by law. We may
                    assign any or all of our rights and obligations to others at
                    any time. We shall not be responsible or liable for any
                    loss, damage, delay, or failure to act caused by any cause
                    beyond our reasonable control. If any provision or part of a
                    provision of these Legal Terms is determined to be unlawful,
                    void, or unenforceable, that provision or part of the
                    provision is deemed severable from these Legal Terms and
                    does not affect the validity and enforceability of any
                    remaining provisions. There is no joint venture,
                    partnership, employment or agency relationship created
                    between you and us as a result of these Legal Terms or use
                    of the Services. You agree that these Legal Terms will not
                    be construed against us by virtue of having drafted them.
                    You hereby waive any and all defenses you may have based on
                    the electronic form of these Legal Terms and the lack of
                    signing by the parties hereto to execute these Legal Terms.
                </p>
                <h2 className="one">23. CHANGE TO TERMS</h2>
                <p className="para">
                    Studybuddy2 LLC reserves the right to modify the Terms under
                    which https://studybuddy.gg/ is offered at its sole
                    discretion. The most recent version of the Terms will
                    replace all previous versions. We encourage you to review
                    the Terms periodically to stay informed about any updates.
                </p>
                <h2 className="one">24. CONTACT US</h2>
                <p className="para">
                    To resolve a complaint regarding the Studybuddy Services or
                    to receive further information regarding use of the
                    Services, please contact us at:{' '}
                    <a href="https://studybuddy.gg/" style={{ color: 'blue' }}>
                        support@studybuddy.gg.
                    </a>
                </p>
                <h2 className="one">25. MOBILE TERMS OF SERVICE</h2>
                <p className="para">
                    The Studybuddy mobile messaging service (the "Service") is
                    operated by Studybuddy2 LLC ("Studybuddy," "we," or "us").
                    By using the Service, you agree to these terms and
                    conditions ("Mobile Terms").
                </p>
                <p className="two">Service Modifications</p>
                <p className="para">
                    We may modify or discontinue the Service or any of its
                    features without prior notice. To the extent permitted by
                    applicable law, we may also update these Mobile Terms at any
                    time. Your continued use of the Service after such changes
                    are effective constitutes your acceptance of the updated
                    terms.
                </p>
                <p className="two">Consent to Receive Messages</p>
                <p className="para">
                    By consenting to Studybuddy’s SMS/text messaging service,
                    you agree to receive recurring SMS/text messages from us to
                    the mobile number you provided. This includes messages
                    related to service updates, alerts, and promotional offers,
                    even if your number is on any state or federal Do Not Call
                    list. Messages may be sent using automated technology.
                </p>
                <p className="two">Costs and Frequency</p>
                <p className="para">
                    While we do not charge for the Service, you are responsible
                    for any charges or fees imposed by your wireless provider.
                    Message frequency may vary, and standard message and data
                    rates may apply. Please check with your mobile plan provider
                    for details.
                </p>
                <p className="two">Opt-Out</p>
                <p className="para">
                    You may opt-out of the Service at any time by texting "STOP"
                    to +18885747091 or by clicking the unsubscribe link in any
                    text message we send. You will receive a one-time
                    confirmation text, and no further messages will be sent
                    unless you initiate them. If you are subscribed to other
                    Studybuddy mobile message programs, you must opt out
                    separately from those programs.
                </p>
                <h2 className="two">Support</h2>
                <p className="para">
                    For assistance email support@studybuddy.gg.
                </p>
                <h2 className="two">Changes and Responsibility</h2>
                <p className="para">
                    We may change the short code or phone number used for the
                    Service and will notify you of such changes. Messages sent
                    to a previous number may not be received, and we are not
                    responsible for honoring requests sent to outdated numbers.
                    Wireless carriers are not liable for delayed or undelivered
                    messages.
                </p>
                <h2 className="two">Mobile Number Updates</h2>
                <p className="para">
                    If you change your mobile number, you must re-register for
                    the Service using your new number.
                </p>
                <h2 className="two">Liability</h2>
                <p className="para">
                    To the extent permitted by law, we are not liable for
                    failed, delayed, or misdirected delivery of information
                    through the Service, errors in such information, or any
                    actions you may or may not take based on the information or
                    Service.
                </p>
                <h2 className="two">Privacy</h2>
                <p className="para">
                    We respect your privacy. For details on how we collect and
                    use your personal information, please refer to our Privacy
                    Notice.
                </p>
            </div>

            <Footer />
        </div>
    );
};
