import { useScreenSize } from '~hooks';
import StudybuddyWriterIcon from '~assets/studybuddy-writer.svg';
import StudybuddyTurboIcon from '~assets/studybuddy-turbo.svg';
import StudybuddyAppIcon from '~assets/studybuddy-app.svg';

import WriterDescriptionDesktop from '~/assets/writer-description-desktop.svg';
import WriterDescriptionMobile from '~/assets/writer-description-mobile.svg';
import AppDescriptionMobile from '~/assets/app-description-mobile.svg';
import AppDescriptionDesktop from '~/assets/app-description-desktop.svg';
import TurboImgDesktop from '~assets/turbo-img-desktop.svg';
import TurboImgMobile from '~assets/turbo-img-mobile.svg';

import AppTextDesktop from '~assets/app-text-desktop.svg';
import AppTextMobile from '~assets/app-text-mobile.svg';

import TurboTextDesktop from '~assets/turbo-text-desktop.svg';
import TurboTextMobile from '~assets/turbo-text-mobile.svg';

export function useToolsCardData() {
    const { isMobile } = useScreenSize();

    const toolsCardData = [
        {
            id: 1,
            isTurbo: false,
            variant: 'writer',
            titleImageSrc: StudybuddyWriterIcon,
            description: isMobile
                ? 'Craft compelling content effortlessly. Write better, faster, and with confidence.'
                : 'Craft compelling content effortlessly with smart suggestions and grammar checks. Write better, faster, and with confidence.',
            descriptionImgSrc: isMobile
                ? WriterDescriptionMobile
                : WriterDescriptionDesktop,
            descriptionImgStyle: isMobile ? { top: '22%' } : { top: '23%' },
        },
        {
            id: 2,
            isTurbo: false,
            variant: 'app',
            titleImageSrc: StudybuddyAppIcon,
            description: isMobile ? AppTextMobile : AppTextDesktop,
            descriptionImgSrc: isMobile
                ? AppDescriptionMobile
                : AppDescriptionDesktop,
            descriptionImgStyle: { top: '18%' },
        },
        {
            id: 3,
            isTurbo: true,
            variant: 'turbo',
            titleImageSrc: StudybuddyTurboIcon,
            description: isMobile ? TurboTextMobile : TurboTextDesktop,
            descriptionImgSrc: isMobile ? TurboImgMobile : TurboImgDesktop,
            descriptionImgStyle: isMobile ? { top: '67%' } : { top: '65%' },
        },
    ];

    return toolsCardData;
}
