import { API, Auth } from 'aws-amplify';
import {
    API_NAME_STRIPE_AWS,
    AppRoutes,
    CREATE_PAYMENT_INTENT_API,
} from '~constants';
import { logger } from '~utils';

export const getClientSecret = async (
    token: string | undefined,
    paymentType: string,
) => {
    if (!token || !paymentType) {
        return;
    }
    try {
        logger.debug('token >>', token, paymentType);
        const response = await API.post(
            API_NAME_STRIPE_AWS,
            CREATE_PAYMENT_INTENT_API,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: {
                    paymentType,
                },
            },
        );

        const { clientSecret } = response;
        logger.debug('clientSecret >>', clientSecret);
        return clientSecret;
    } catch (err: any) {
        logger.error(err);
    }

    /* navigate(AppRoutes.Login, { replace: true, state: { error: "Something went wrong. Please try again or contact support." } }); */
};

export const getUserDetails = async (navigate: any) => {
    try {
        const response = Auth.currentAuthenticatedUser();

        logger.debug('Received user>> ' + response);
        return response;
    } catch (err: any) {
        navigate(AppRoutes.Login, {
            replace: true,
            state: { error: err.message },
        });
    }
};
