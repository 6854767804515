import { AppRoutes } from '~constants';
import type { FC } from 'react';
import type { HeaderProps } from './Header.types';

import './Header.scss';

export const Header: FC<HeaderProps> = (props) => {
    const { logoAlign } = props;

    const logoAlignClass = logoAlign ? `logo-align-${logoAlign}` : '';

    return (
        <div className={`header ${logoAlignClass}`}>
            <div
                className="logo"
                onClick={() => (window.location.href = AppRoutes.Dashboard)}
            >
                <img src="/assets/images/newLogo.png" alt="logo" />
            </div>
        </div>
    );
};
