import React, { useState } from 'react';

export const FaqItem = ({
    question,
    answer,
    initState,
}: {
    question: string;
    answer: string;
    initState: boolean;
}) => {
    const [isActive, setIsActive] = useState(initState);

    const handleToggle = () => {
        setIsActive(!isActive);
    };

    return (
        <div className="faqItem" onClick={handleToggle}>
            <div className="faqItemTop">
                <p className="faqItemTitle">{question}</p>
                <div
                    className={`faqItemImageContainer ${
                        isActive ? 'active' : ''
                    }`}
                >
                    <img
                        src={'/assets/icons/chevron-down-grey.svg'}
                        alt="faq toggle"
                        className="faqIcon"
                    />
                </div>
            </div>
            <div className={`faqItemBottom ${isActive ? 'active' : ''}`}>
                <div>
                    <p className="faqContent">{answer}</p>
                </div>
            </div>
        </div>
    );
};
