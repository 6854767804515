export const CompanyLogoItems = [
    '/assets/images/platforms/svgs/canvas.svg',
    '/assets/images/platforms/svgs/blackboard.svg',
    '/assets/images/platforms/svgs/moodle.svg',
    '/assets/images/platforms/svgs/tophat.svg',
    '/assets/images/platforms/svgs/schoology.svg',
    '/assets/images/platforms/svgs/d2l.svg',
    '/assets/images/platforms/svgs/respondus.svg',
    '/assets/images/platforms/svgs/userGroup.svg',
];
