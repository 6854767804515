import { Assets } from '~constants';

export type OnboardingStepType = {
    id: number;
    stepName?: string;
    title: string;
    subTitle?: string;
    bulletPoints?: string[];
    subBulletPoints?: { title: string; bulletPoints: string[] }[];
    addtionalText?: string;
    assetPath?: string;
    assetType?: 'video' | 'image';
};

export const onboardingSteps: OnboardingStepType[] = [
    {
        id: 1,
        stepName: 'Download',
        title: 'Step 1: Download AI Chrome Extension to your computer',
        assetPath: Assets.onboardingStep1,
        assetType: 'image',
    },
    {
        id: 2,
        stepName: 'Unzipping',
        title: 'Step 2: Unzipping the Folder',
        bulletPoints: [
            'Locate the downloaded folder, usually in the [[Downloads]] folder on your computer.',
        ],
        subBulletPoints: [
            {
                title: 'For Mac users:',
                bulletPoints: [
                    'Double-click the zipped file to automatically unzip it.',
                ],
            },
            {
                title: 'For Windows users:',
                bulletPoints: [
                    'Right-click the zipped folder and select "Extract All."',
                    'Follow the prompts to complete the extraction.',
                ],
            },
        ],
        assetPath: Assets.onboardingStep2,
        assetType: 'video',
    },
    {
        id: 3,
        stepName: 'Access Extensions',
        title: 'Step 3: Access Extensions',
        bulletPoints: [
            'Open [[Google Chrome.]]',
            'Click on the [[three dots]] in the top right corner of the screen.',
            'Select [["Extensions"]] from the dropdown menu.',
            'Click on [["Manage Extensions."]]',
        ],
        assetPath: Assets.onboardingStep3,
        assetType: 'video',
    },
    {
        id: 4,
        stepName: 'Add to Chrome',
        title: 'Step 4: Add to Chrome',
        bulletPoints: [
            'Enable [[Developer Mode]] in the top right corner of the Extensions page.',
            'Click on [["Load unpacked."]]',
            '[[Select the folder]] you unzipped earlier.',
            'Click on [["Open."]]',
            'Ensure the extension is [[enabled]] by toggling the switch next to it.',
        ],
        assetPath: Assets.onboardingStep4,
        assetType: 'video',
    },
    {
        id: 5,
        stepName: 'Pin Extension',
        title: 'Step 5: Pin Extension',
        bulletPoints: [
            'Click on the [[Extension]] button in the top right corner of the screen.',
            '[[Pin the extension]] for easy access whenever you need it.',
            `And voilà, it's time to start crushing your grades!`,
        ],
        assetPath: Assets.onboardingStep5,
        assetType: 'video',
    },
];

export const popupOnboardingArr = [
    {
        id: 1,
        title: 'Feedback Popup',
        subTitle: `Feedback popup`,
    },
    {
        id: 2,
        title: 'Video Popup',
        subTitle: `Video popup`,
    },
    {
        id: 3,
        title: 'Submit Form',
        subTitle: `Submit Form`,
    },
];
