import './ReferralPopup.styles.scss';
import Share from './Share.svg';
import FacebookShareIcon from './FacebookShareIcon.svg';
import WhatsappShareIcon from './WhatsappShareIcon.svg';
import TwitterShareIcon from './TwitterShareIcon.svg';
import { useAuth, useSnackbar } from '~hooks';
import { useEffect, useState } from 'react';
import { logger } from '~utils';
import {
    createFacebookShareLink,
    createTwitterShareLink,
    createWhatsAppShareLink,
} from '~utils/ShareLink';
import { VIRAL_SHARE_TEXT } from '~components/Modals/SharePopup/SharePopup.constants';
import { useTranslation } from 'react-i18next';

export const ReferralPopup = ({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (v: boolean) => void;
}) => {
    const { user } = useAuth();
    const [referralUrl, setReferralUrl] = useState<string>('');
    const { successSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        let code = user?.data?.attributes['custom:stripeCustomerID'];
        if (!code || !code.startsWith('cus_')) {
            logger.error(
                'No Stripe Customer ID found when trying to create referral code' as any,
            );
        } else {
            code = code.replace('cus_', '');
            setReferralUrl(`https://studybuddy.gg?ref=${code}`);
        }
    }, [user]);

    if (!open) {
        return null;
    }

    return (
        <div className="ReferralModal" onClick={() => setOpen(false)}>
            <div
                className="Referral-popup-content"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className="Referral-header-text">
                    {t('invite_a_friend_get_a')}{' '}
                    <span style={{ color: '#8f5dff' }}>{t('free_week')}</span>
                </div>
                <button
                    onClick={() => {
                        navigator.clipboard.writeText(referralUrl).then(() => {
                            successSnackbar(t('referral_success'));
                        });
                    }}
                    className="ReferralCopyButton"
                >
                    <img src={Share} alt="share icon" />
                    <div className="ReferralCopyButtonText">
                        {t('copy_link')}
                    </div>
                </button>
                <div style={{ display: 'flex', gap: 10 }}>
                    {/*Facebook*/}
                    <img
                        style={{ cursor: 'pointer' }}
                        src={FacebookShareIcon}
                        alt="facebook share icon"
                        onClick={() => {
                            openLinkInNewTab(
                                createFacebookShareLink(referralUrl),
                            );
                        }}
                    />
                    {/*Whatsapp*/}
                    <img
                        style={{ cursor: 'pointer' }}
                        src={WhatsappShareIcon}
                        alt="WhatsApp share icon"
                        onClick={() => {
                            openLinkInNewTab(
                                createWhatsAppShareLink(
                                    VIRAL_SHARE_TEXT + '\n\n' + referralUrl,
                                ),
                            );
                        }}
                    />
                    {/*Twitter*/}
                    <img
                        style={{ cursor: 'pointer' }}
                        src={TwitterShareIcon}
                        alt="Twitter share icon"
                        onClick={() => {
                            openLinkInNewTab(
                                createTwitterShareLink(
                                    VIRAL_SHARE_TEXT + '\n\n' + referralUrl,
                                ),
                            );
                        }}
                    />
                </div>
                <div
                    className="ReferralNotInterested"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    {t('no_free_week')}
                </div>
            </div>
        </div>
    );
};

const openLinkInNewTab = (url: string) => {
    window.open(url, '_blank');
};
