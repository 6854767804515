import React, { FC, useEffect, useState } from 'react';

import { CK_ID, SEMESTER_PLAN_PRICE_ID } from '~constants';
import { useAuth, useSnackbar } from '~hooks';
import { getStripeUserDetails, loadCardDetails } from './PaymentPlan.functions';
import type {
    BillingDetails,
    DefaultPaymentDetails,
    PaymentPlanProps,
} from './PaymentPlan.types';
import { PaymentPlanHeader } from './PaymentPlanHeader';
import { SubscriptionDetails } from './SubscriptionDetails';

import {
    getChurnkeyHash,
    newCancellation,
    submitCancellationEvents,
} from '~components/CancellationFlow/CancellationFlow.functions';
import { getChurnkeyEnvironment, logger } from '~utils';
import { SemesterPlan } from './SemesterPlan';
import { Loader } from '~components/Loader/Loader';
import { SummerCancelPopup } from '~components/Modals/SummerCancelPopup';

import './PaymentPlan.styles.scss';
import { useTranslation } from 'react-i18next';

export const PaymentPlan: FC<PaymentPlanProps> = (props) => {
    const [customerId, setCustomerId] = useState<string>('');
    const [defaultPaymentDetails, setDefaultPaymentDetails] =
        useState<DefaultPaymentDetails | null>(null);
    const [billingDetails, setBillingDetails] = useState<BillingDetails | null>(
        null,
    );

    const [isShowSummerPopup, setIsShowSummerPopup] = useState(false);
    const [summerPauseIsLoading, setSummerPauseIsLoading] = useState(false);

    const { t } = useTranslation();

    const { user, token, planStatus } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const { errorSnackbar, infoSnackbar, successSnackbar } = useSnackbar();
    const isShowSemesterPlan =
        billingDetails &&
        (!billingDetails?.planId ||
            billingDetails?.planId !== SEMESTER_PLAN_PRICE_ID);

    useEffect(() => {
        if (!user) return;
        getDetails();
    }, [user]);

    const getDetails = async () => {
        const customerId = user?.data?.attributes['custom:stripeCustomerID'];

        setCustomerId(customerId);

        const response = await getStripeUserDetails(token, customerId, t);
        if (response) {
            const {
                planType,
                planEnd,
                planId,
                daysLeft,
                billingEmail,
                updatePaymentUrl,
                defaultPaymentMethod,
                metadata,
                balance,
                couponPercentOff,
            } = response;

            setBillingDetails({
                status: planStatus,
                planType,
                planEnd,
                planId,
                daysLeft,
                billingEmail,
                updatePaymentUrl,
                defaultPaymentMethod,
                metadata,
                balance,
                couponPercentOff,
            });

            if (defaultPaymentMethod) {
                const res = await loadCardDetails(token, defaultPaymentMethod);
                setDefaultPaymentDetails({
                    brand: res?.brand,
                    cardEnding: res?.last4,
                    expiryMonth: res?.exp_month,
                    expiryYear: res?.exp_year,
                });
            }
        }
    };

    const onCancelClick = () => {
        setIsShowSummerPopup(true);
    };

    const onCancelPopupCloseHandler = () => {
        setIsShowSummerPopup(false);
    };

    const onCancelPopupPassHandler = () => {
        setIsShowSummerPopup(false);
        handleCancelPlan();
    };

    const handleNewCancelPlan = async () => {
        setSummerPauseIsLoading(true);
        infoSnackbar('Pausing your subscription...', 2000);
        const customerId = user?.data?.attributes['custom:stripeCustomerID'];
        console.log('customerId >>>', customerId);
        const res = await newCancellation(token, customerId);
        logger.debug('handleNewCancelPlan >>>', res);
        if (res && res === 'success') {
            successSnackbar(
                'Your subscription has been paused until September 1st. We will miss you!',
            );
            setSummerPauseIsLoading(false);
            setIsShowSummerPopup(false);
        } else {
            setSummerPauseIsLoading(false);
            errorSnackbar(
                'Something went wrong while trying to pause your subscription. please try again later.',
            );
        }
    };

    const handleCancelPlan = async () => {
        setLoading(true);

        const customerId = user?.data?.attributes['custom:stripeCustomerID'];
        const email = user?.data?.attributes.email;

        getChurnkeyHash(token, customerId)
            .then((val) => {
                logger.debug('Received hash val: ', val);
                (window as any).churnkey.init('show', {
                    customerId: customerId,
                    authHash: val,
                    appId: CK_ID,
                    mode: getChurnkeyEnvironment(),
                    provider: 'stripe',
                    onClose: () => {
                        setLoading(true);
                        // Reload after 1 second to let all API calls complete
                        setTimeout(() => {
                            window.location.reload();
                        }, 1000);
                    },
                    handleSupportRequest: () => {
                        infoSnackbar(
                            'Please email support@studybuddy.gg. I copied the email to your clipboard!',
                        );
                        navigator.clipboard.writeText('support@studybuddy.gg');
                        (window as any).churnkey.hide();
                    },
                    onCancel: () => {
                        submitCancellationEvents(email);
                    },
                    onError: () => {
                        errorSnackbar(
                            'Something went wrong while trying to cancel. Please email support@studybuddy.gg and let them know you received this error while trying to cancel.',
                        );
                    },
                });
            })
            .catch((e) => {
                errorSnackbar(
                    'Something went wrong while trying to cancel. Please email support@studybuddy.gg and let them know you received this error while trying to cancel.',
                    10000,
                );
                logger.error(e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="payment-plan">
            <div className="heading">
                <h1>{t('billing')}</h1>
                <p>{t('manage_your_sub')}</p>
            </div>
            {loading && <Loader />}
            <PaymentPlanHeader
                open={billingDetails !== null}
                data={billingDetails}
                planStatus={planStatus}
                token={token}
                customerId={customerId}
            />
            {isShowSemesterPlan && (
                <SemesterPlan token={token} customerId={customerId} />
            )}
            <SummerCancelPopup
                open={isShowSummerPopup}
                onClose={onCancelPopupCloseHandler}
                isLoading={summerPauseIsLoading}
                onCtaClickHandler={handleNewCancelPlan}
                onNoThanksClickHandler={onCancelPopupPassHandler}
            />
            <div className="card-informations">
                <SubscriptionDetails
                    billingData={billingDetails}
                    cardData={defaultPaymentDetails}
                />
            </div>
            <p className="cancel-cta" onClick={onCancelClick}>
                {t('click_to_pause')}
            </p>
        </div>
    );
};
