import { EXTENSION_ID } from "~constants";
import { logger } from "./logger";
import { useEffect, useState } from "react";

export const useExtensionHelper = () => {
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(true);
    // The sent message state is used to force a re-render of the state
    const [sentMessage, setSentMessage] = useState(false);

    useEffect(() => {
        checkIfExtensionIsInstalled();
    }, []);

    const checkIfExtensionIsInstalled = () => {
        try {
            logger.info("Checking if extension is installed");
            chrome.runtime.sendMessage(EXTENSION_ID, { message: "PING" },
                function (response) {
                    logger.debug("Pinged extension and received response: " + response);
                    if (response) {
                        logger.debug("Extension received PING message successfully");
                        setIsExtensionInstalled(true);
                    } else {
                        logger.warn("Extension is likely installed but failed to respond to PING request.");
                        setIsExtensionInstalled(false);
                    }
                    setSentMessage(!sentMessage);
                });
        } catch (error) {
            logger.warn("Failed to send PING message to the extension: " + error);
            setIsExtensionInstalled(false);
            setSentMessage(!sentMessage);
        }
    }

    return { isExtensionInstalled };
}