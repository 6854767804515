import React, { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { NewsletterPopupProps } from './Newsletter.types';

import './Newsletter.styles.scss';
import { useTranslation } from 'react-i18next';

export const NewsletterPopup: FC<NewsletterPopupProps> = (props) => {
    const { show, handleClose } = props;

    const [isLoading, setIsLoading] = useState(false);

    const onNewsletterSubmitHandler = async (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        event.preventDefault();
        setIsLoading(true);
        const data = new FormData(event.currentTarget);
        const name = data.get('name') as string;
        const email = data.get('email') as string;
        console.log('Name: >>>', name);
        console.log('Email: >>>', email);
        // let splitedName = splitName(name);

        /* (window as any).klaviyo.identify({
            $email: email,
            $first_name: splitedName.firstName,
            $last_name: splitedName.lastName,
        }); */

        // add to newsletter
        let response = await fetch(
            `https://api.maltertech.com/studybuddy/newsletter.php`,
            {
                method: 'POST',
                body: data,
            },
        ).then((response) => response.json());

        if (response.error) {
            alert(response.error);
            setIsLoading(false);
        } else {
            alert("You've successfully subscribed to our newsletter!");
            handleClose();
        }
        setIsLoading(false);
    };

    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            contentClassName="modalContent"
        >
            <div className="newsletter-popup">
                {/* <div className="newsletter-close-btn" onClick={handleClose}>
                    <img src="assets/images/close-icon.svg" alt="close" />
                </div> */}

                <form
                    className="newsletter-body"
                    onSubmit={onNewsletterSubmitHandler}
                >
                    <h3>{t('before_you_go')}...</h3>
                    <p>{t('newsletter_body')}</p>
                    <div className="input-container">
                        <label>
                            Name<span>*</span>
                        </label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            required
                        />
                    </div>

                    <div className="input-container">
                        <label>
                            Email<span>*</span>
                        </label>
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            required
                        />
                    </div>

                    <button
                        className="popup-cta-btn"
                        type="submit"
                        disabled={isLoading}
                    >
                        {t('subscribe')}
                        {/* <img src="assets/images/arrow-right.svg" alt="arrow" /> */}
                    </button>
                    <button onClick={handleClose} className="popup-noThanks">
                        {t('no_thanks')}
                    </button>
                </form>
            </div>
        </Modal>
    );
};
