import { Assets } from '~constants';

export const partnerLogos = [
    Assets.partnerLogoMoodle,
    Assets.partnerLogoCanvas,
    Assets.partnerLogoTopHat,
    Assets.partnerLogoBlackboard,
];

export const institutions = [
    '/assets/images/institutions/harvard.png',
    '/assets/images/institutions/mit.png',
    '/assets/images/institutions/berkeley.png',
    '/assets/images/institutions/stanford.png',
    '/assets/images/institutions/chicago.png',
    '/assets/images/institutions/princeton.png',
    '/assets/images/institutions/usc.png',
    '/assets/images/institutions/northeastern.png',
    '/assets/images/institutions/wisconsin.png',
    '/assets/images/institutions/texas.png',
    '/assets/images/institutions/michigan.png',
    '/assets/images/institutions/johns-hopkins.png',
];
