import { Form, InputGroup } from 'react-bootstrap';
import './Referral.styles.scss';
import { useAuth, useSnackbar } from '~hooks';
import { useEffect, useState } from 'react';
import { logger } from '~utils';
import { icons } from '~constants';
import { VIRAL_SHARE_TEXT } from '~components/Modals/SharePopup/SharePopup.constants';
import { useTranslation } from 'react-i18next';

export const Referral = () => {
    const { user } = useAuth();
    const [referralUrl, setReferralUrl] = useState<string>('');
    const { successSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        let code = user?.data?.attributes['custom:stripeCustomerID'];
        if (!code || !code.startsWith('cus_')) {
            logger.error(
                'No Stripe Customer ID found when trying to create referral code' as any,
            );
        } else {
            code = code.replace('cus_', '');
            setReferralUrl(`https://studybuddy.gg?ref=${code}`);
        }
    }, [user]);

    const copyToClipboard = (event: any) => {
        event.preventDefault();
        navigator.clipboard.writeText(VIRAL_SHARE_TEXT + ' ' + referralUrl);
        successSnackbar(t('referral_success'));
    };

    return (
        <div className="refer">
            <h2>{t('Referral Program')}</h2>
            {/* <InputGroup> */}
            {/* <Form.Control
                    value={referralUrl}
                    readOnly
                    onClick={copyToClipboard}
                /> */}
            <button className="referral-button" onClick={copyToClipboard}>
                <img src={icons.copy} alt="copy" /> {t('Copy Link')}
            </button>
            {/* </InputGroup> */}

            {/* <Col className='share-icons' md={12} lg={5}>
                    <Stack direction="horizontal" gap={3}>
                        <b>Share </b>
                        <a href={`sms:?&body=${encodeURIComponent(VIRAL_SHARE_TEXT + ' ' + referralUrl)}`}>
                            <img src={Assets.iMessageLogo} />
                        </a>
                        <a href={`https://wa.me/?text=${encodeURIComponent(VIRAL_SHARE_TEXT + ' ' + referralUrl)}`}
                            target='_blank'>
                            <img src={Assets.whatsAppLogo} />
                        </a>
                        <a href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(VIRAL_SHARE_TEXT + ' ' + referralUrl)}`}
                            target='_blank'>
                            <img src={Assets.twitterLogo} />
                        </a>
                        <a href={`https://telegram.me/share/url?url=${encodeURIComponent(referralUrl)}&text=${VIRAL_SHARE_TEXT}`}
                            target='_blank'>
                            <img src={Assets.telegramLogo} />
                        </a>
                    </Stack>
                </Col> */}
        </div>
    );
};
