export const institutionsItem = [
    '/assets/images/institutions/svgs/harvard.svg',
    '/assets/images/institutions/svgs/mit.svg',
    '/assets/images/institutions/svgs/berkeley.svg',
    '/assets/images/institutions/svgs/stanford.svg',
    '/assets/images/institutions/svgs/chicago.svg',
    '/assets/images/institutions/svgs/princeton.svg',
    '/assets/images/institutions/svgs/usc.svg',
    '/assets/images/institutions/svgs/northeastern.svg',
    '/assets/images/institutions/svgs/wisconsin.svg',
    '/assets/images/institutions/svgs/texas.svg',
    '/assets/images/institutions/svgs/michigan.svg',
    '/assets/images/institutions/svgs/johns-hopkins.svg',
];
