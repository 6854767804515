import { icons } from "~constants";

export const getCardImage = (brand: string | undefined) => {
    switch (brand) {
        case 'visa':
            return icons.visa;
        case 'mastercard':
            return icons.mastercard;
        case 'amex':
            return icons.amex;
        case 'discover':
            return icons.discover;
        default:
            return icons.card;
    }
}