import { FAQ } from '~components/FAQ/FAQ';

import './DashboardContact.styles.scss';
import { useTranslation } from 'react-i18next';

export const DashboardContact = () => {
    const { t } = useTranslation();

    return (
        <div className="dashboard-contact">
            <h1 className="title">{t('contact_us')}</h1>
            <div className="dashboard-contact__content">
                <FAQ />
            </div>
        </div>
    );
};
