import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { TrialPopupProps } from './TrialPopup.types';

import { useTranslation } from 'react-i18next';
import TryNowPopupDesktop from '~assets/try-popup-desktop.svg';
import TryNowPopupMobile from '~assets/try-popup-mobile.svg';
import Join530kDesktop from '~assets/join_530k-desktop.svg';
import Join530kMobile from '~assets/join_530k-mobile.svg';

import './TrialPopup.styles.scss';
import { useScreenSize } from '~hooks';
import Marquee from 'react-fast-marquee';
import '../../../pages/Homepage/Institutions/Institutions.styles.scss';
import { institutionsItem } from '~pages/Homepage/Institutions/Institutions.constants';

export const TrialPopup: FC<TrialPopupProps> = (props) => {
    const { show, handleClose, handleCTA } = props;

    const onPopupPricingClickHandler = () => {
        handleCTA();
    };

    const { isMobile } = useScreenSize();

    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            contentClassName="modalContent"
        >
            <div className="trialContent">
                {/* <div className="trial-header">
                    <div className="header-logo">
                        <img src="/assets/images/newLogo.png" alt="logo" />
                    </div>
                    <div
                        id="trial-close-btn"
                        className="close-btn"
                        onClick={handleClose}
                    >
                        <img src="assets/images/close-icon.svg" alt="close" />
                    </div>
                </div> */}
                <img
                    src={isMobile ? TryNowPopupMobile : TryNowPopupDesktop}
                    alt="try_now"
                />
                <div className="trial-body">
                    {/* <p className="regular-price">$5.99/{t('week')}</p> */}

                    <button
                        className="popup-cta-btn"
                        onClick={onPopupPricingClickHandler}
                    >
                        {t('get_started')}
                        {/* <img src="assets/images/arrow-right.svg" alt="arrow" /> */}
                    </button>
                    <button onClick={handleClose} className="popup-noThanks">
                        {t('no_thanks')}
                    </button>
                </div>
                <img
                    className="join-530k"
                    src={isMobile ? Join530kMobile : Join530kDesktop}
                    alt="join-530k"
                />
                <div
                    style={{ marginTop: '16px' }}
                    className="institution-marquee-container"
                >
                    <Marquee className="company-marquee-container">
                        {institutionsItem.map((item, index) => (
                            <div
                                className="institution-marquee-item"
                                key={index}
                            >
                                <img
                                    className="marquee-image"
                                    src={item}
                                    alt="institution"
                                />
                            </div>
                        ))}
                    </Marquee>
                </div>
            </div>
        </Modal>
    );
};
