import { Elements } from '@stripe/react-stripe-js';
import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { FC, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
    AppRoutes,
    Assets,
    defaultErrorMessage,
    icons,
    STRIPE_PUBLISHABLE_KEY,
} from '~constants';
import { useAuth, useScreenSize } from '~hooks';
import { appearance } from './PaymentDetails.constants';
import { getClientSecret } from './PaymentDetails.functions';
import { CheckoutForm } from '~components/CheckoutForm';
import { FancyLoader, Loader } from '~components/Loader';
import { RegisterSidePanel } from '~components/RegisterSidePanel';

import { logger } from '~utils';
import { emitInitiateCheckoutEvent } from '~utils/advertisingEvents';

// import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useTranslation } from 'react-i18next';

import './PaymentDetails.styles.scss';
import { Hub } from 'aws-amplify';
// import { Auth } from 'aws-amplify';

type PaymentDetailsProps = {
    isPopupCheckout?: boolean;
};

export const PaymentDetails: FC<PaymentDetailsProps> = (props) => {
    const { isPopupCheckout } = props;
    // const [clientSecret, setClientSecret] = useState('');
    const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const [searchParams, _] = useSearchParams();
    const redirect_status = searchParams.get('redirect_status') || '';
    const payment_status = searchParams.get('payment_status') || '';
    const historyState = useLocation().state;
    const { t } = useTranslation();

    const { updateAuthState, isAuthenticated, planStatus, token, logout } =
        useAuth();
    const navigate = useNavigate();
    const options = { appearance, mode: 'subscription' };

    // Emit events for successful registration
    useEffect(() => {
        if (isPopupCheckout) {
            emitInitiateCheckoutEvent();
        }
    }, []);

    // Use Effect to emit payment for ads if successful. Stripe will redirect user back to this page.
    useEffect(() => {
        if (payment_status === 'success') {
            // Save to session storage a key that's used to inform the dashboard that the payment was successful
            sessionStorage.setItem('payment_status', 'success');
        }
    }, [payment_status]);

    const startPaymentProcess = async () => {
        setIsLoading(false);
        console.log('startPaymentProcess ___');
        try {
            const stPromise = await loadStripe(STRIPE_PUBLISHABLE_KEY);
            setStripePromise(stPromise);
            if (!stPromise) {
                return;
            }
        } catch (err: any) {
            logger.error(err);
            navigate(AppRoutes.Login, {
                replace: true,
                state: {
                    error: defaultErrorMessage,
                },
            });
        }
    };

    const checkAccountStatus = async () => {
        /* const currentUser = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });
        console.log('currentUser ___', currentUser);
        const userToken = currentUser?.signInUserSession?.idToken?.jwtToken;
        console.log('userToken ___', userToken); */

         
        if (isAuthenticated && planStatus) {
            navigate(AppRoutes.Dashboard, { replace: true });
        } else { 
            startPaymentProcess();
        }
    };

    /* const refreshUserData = async () => {
        await updateAuthState();
    }; */

    const onLogoutClick = async () => {
        await logout();
    };

    // Emit events for successful registration
    useEffect(() => {
        if (
            historyState &&
            historyState?.event &&
            historyState?.event === 'InitiateCheckout'
        ) {
            setIsLoading(false);
            logger.debug('History', historyState);
            emitInitiateCheckoutEvent();
        }
        if (redirect_status === 'succeeded') {
            navigate(AppRoutes.Dashboard, { replace: true });
            return;
        }
        checkAccountStatus();
    }, []);

    /* useEffect(() => {
        checkAccountStatus();
    }, []); */

    /* useEffect(() => {
        setTimeout(async () => {
            await updateAuthState();
            if (redirect_status === 'succeeded') {
                navigate(AppRoutes.Dashboard, { replace: true });
                return;
            }
            refreshUserData();
        }, 5000);
    }, []); */

    if (
        isLoading ||
        redirect_status === 'succeeded' ||
        payment_status === 'success'
    ) {
        return <FancyLoader />;
    }

    Hub.listen('auth', (data) => {
        console.log('Auth HUB event ___', data.payload);
        if (data.payload.event === 'signIn') {
            updateAuthState();
        }
    });

    return (
        <div className="payment-details-container">
            <Row className="payment-details">
                <Col className="payment-section" lg={5} md={12}>
                    <div className="payment-form-section">
                        <h1>3 Days $0.99 Trial</h1>
                        <h3>Enter payment info to get access</h3>

                        <img
                            className="safe-checkout"
                            src={Assets.SafeCheckout}
                            alt="safe checkout"
                        />

                        {/* <PaypalButton /> */}
                        {stripePromise ? (
                            <div className="payment-form">
                                <Elements
                                    options={{
                                        appearance,
                                        mode: 'payment',
                                        amount: 100,
                                        currency: 'usd',
                                        capture_method: 'manual',
                                        setup_future_usage: 'off_session',
                                    }}
                                    stripe={stripePromise}
                                >
                                    <CheckoutForm />
                                </Elements>
                            </div>
                        ) : (
                            <Loader />
                        )}
                    </div>
                    <button className="logout" onClick={onLogoutClick}>
                        <img src={icons.rightArrowWhite} alt="arrow" />
                        <p>{t('back')}</p>
                    </button>
                </Col>
                <Col className="info-section" lg={7} md={0}>
                    <RegisterSidePanel isCheckoutPage />
                </Col>
            </Row>
        </div>
    );
};
