export const computeInputFieldStyles = (style: any) => {
    return {
        inputLabelStyles: {
            fontSize: style?.labelFontSize || '0.9rem',
            marginBottom: style?.labelMarginBottom || '1rem',
            color: style?.labelColor || '#ffffff',
        },
        inputStyles: {
            height: style?.height || '3rem',
            fontSize: style?.fontSize || '0.9rem',
            marginBottom: style?.marginBottom || '1rem',
            color: style?.color || '#ffffff',
            padding: style?.padding || '0 16px',
            borderRadius: style?.borderRadius || '10px',
            border: style?.border || '1px solid #ffffff',
            background: style?.background || 'transparent',
        }
    }
}

