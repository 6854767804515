import React, { useState } from 'react';

import { useAuth } from '~/hooks';
import { icons } from '~constants';
import { Loader } from '~components/Loader';
import UserAccIcon from '../../assets/user.svg';

import { useTranslation } from 'react-i18next';

import { useSnackbar } from '~hooks';
import { logger } from '~utils';
import { inputFieldStyles } from './EditUserInfo/EditUserInfo.constants';
import { updateUserApi } from './EditUserInfo/EditUserInfo.functions';
import { InputField } from '~components/InputField';

import './UserInfo.styles.scss';
import './EditUserInfo/EditUserInfo.styles.scss';

export const UserInfo = () => {
    const [isEditActive, setIsEditActive] = useState(false);

    const { user } = useAuth();

    const firstName = user?.data?.attributes.given_name || '';
    const lastName = user?.data?.attributes.family_name || '';
    const email = user?.data?.attributes.email;
    const token = user?.data?.signInUserSession.idToken.jwtToken;
    const userData = { firstName, lastName, email, token };

    const { successSnackbar, errorSnackbar } = useSnackbar();

    const [formData, setFormData] = useState({
        firstName: userData?.firstName || '',
        lastName: userData?.lastName || '',
    });

    const onInputChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = async () => {
        try {
            const res = await updateUserApi(userData.token, formData);
            if (res === 'Successfully updated name') {
                successSnackbar('User info updated successfully');
                window.location.reload();
            }
        } catch (error: any) {
            errorSnackbar('Something went wrong, try again');
            logger.error(error);
        }
    };

    const { t } = useTranslation();

    const onEditClick = () => {
        setIsEditActive(!isEditActive);
    };

    return (
        <div className="userInfo">
            {/* <div className="heading">
                <h1>{t('personal_info')}:</h1>
                <p>{t('manage_personal_info')}</p>
            </div> */}
            {user ? (
                <div className="user-info-section">
                    <div className="user-info-header">
                        <div className="user-acc">
                            <img src={UserAccIcon} alt="user-icon" />
                            <h3 className="account-title">Account</h3>
                        </div>
                        {!isEditActive ? (
                            <button className="edit-btn" onClick={onEditClick}>
                                <p>{t('edit')}</p>
                                <img src={icons.edit} alt="edit" />
                            </button>
                        ) : (
                            <button
                                style={{ display: 'block' }}
                                className="edit-btn"
                                onClick={onSubmit}
                            >
                                <p>{t('Save')}</p>
                            </button>
                        )}
                    </div>

                    {isEditActive ? (
                        <div className="edit-user-section">
                            <div className="edit-user-info">
                                <div className="edit-items">
                                    <div className="edit-items__item">
                                        <InputField
                                            type="text"
                                            label={t('first_name')}
                                            name="firstName"
                                            placeholder={t('first_name')}
                                            value={formData.firstName}
                                            onChange={onInputChange}
                                            style={inputFieldStyles}
                                        />
                                    </div>
                                    <div className="edit-items__item">
                                        <InputField
                                            type="text"
                                            label={t('last_name')}
                                            name="lastName"
                                            placeholder={t('last_name')}
                                            value={formData.lastName}
                                            onChange={onInputChange}
                                            style={inputFieldStyles}
                                        />
                                    </div>
                                    <div className="edit-items__item">
                                        <InputField
                                            type="email"
                                            label={t('Email Address')}
                                            name="email"
                                            placeholder="example@email.com"
                                            value={userData.email}
                                            onChange={() => {}}
                                            style={inputFieldStyles}
                                            disabled={true}
                                        />
                                        {/* <p>* {t('email_cannot_be_changed')}</p> */}
                                    </div>
                                </div>
                                {/* <button onClick={onSubmit}>
                                    {t('save')}{' '}
                                    <img
                                        src={icons.rightArrowWhite}
                                        alt="arrow"
                                    />
                                </button> */}
                            </div>
                        </div>
                    ) : (
                        <div className="info-items">
                            <div className="info-items__item">
                                <h3>{t('first_name')}</h3>
                                <div className="info-items__textInput">
                                    <p>{firstName}</p>
                                </div>
                            </div>
                            <div className="info-items__item">
                                <h3>{t('last_name')}</h3>
                                <div className="info-items__textInput">
                                    <p>{lastName}</p>
                                </div>
                            </div>
                            <div className="info-items__item">
                                <h3>{t('email_address')}</h3>
                                <div className="info-items__textInput">
                                    <p>{email}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <Loader />
            )}
        </div>
    );
};
