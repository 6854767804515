import type { FC } from 'react';
import React from 'react';

import type { SubmitEmailProps } from './SubmitEmail.types';
import { inputFieldStyles } from '../ForgetPassword.constants';
import { InputField } from '~components/InputField';
import { Loader } from '~components/Loader';

import './SubmitEmail.styles.scss';
import { useTranslation } from 'react-i18next';

export const SubmitEmail: FC<SubmitEmailProps> = (props) => {
    const { onChange, formData, formErrors, onForgetPassword, isLoading } =
        props;

    const { t } = useTranslation();

    return (
        <div className="submit-email-section">
            <InputField
                name="email"
                type="email"
                label={t('email')}
                value={formData.email}
                onChange={onChange}
                placeholder={t('enter_email')}
                style={inputFieldStyles}
                error={formErrors?.email}
            />
            <button onClick={onForgetPassword} className="cta-btn">
                {isLoading ? (
                    <Loader color="#fff" />
                ) : (
                    <span>{t('send_code')}</span>
                )}
            </button>
        </div>
    );
};
