import React, { FC } from 'react';

import thumbsUp from '../../../assets/thumbs-up.svg';
import thumbsDown from '../../../assets/thumbs-down.svg';
import './FeedbackPopup.styles.scss';
// import { useNavigate } from 'react-router-dom';
import { emitOnboardingFeedbackEvent } from '~utils/advertisingEvents';
// import { AppRoutes } from '~constants';

type Props = {
    open: boolean;
    step: number;
    onClose: () => void;
    onLeftButtonClick: () => void;
    onRightButtonClick: () => void;
    increaseStep: () => void;
};

export const FeedbackPopup: FC<Props> = (props) => {
    const { open, onClose, step, onRightButtonClick, increaseStep } = props;

    // const navigate = useNavigate();

    const handleClose = () => {
        onClose();
    };

    const handlePositiveFeedback = () => {
        emitOnboardingFeedbackEvent(true);
        onRightButtonClick();
        onClose();
        window.location.reload();
    };
    const handleNegativeFeedback = () => {
        emitOnboardingFeedbackEvent(false);
        increaseStep();
    };

    if (!open) {
        return null;
    }

    return (
        <div className="feedback-popup">
            <div className="feedback-content">
                <h3>
                    Did you successfully download and get Studybuddy to work?
                </h3>
                <div className="thumbs-container">
                    <div onClick={handlePositiveFeedback} className="thumbs-up">
                        <img src={thumbsUp} alt="thumbs-up" />
                    </div>
                    <div
                        onClick={handleNegativeFeedback}
                        className="thumbs-down"
                    >
                        <img src={thumbsDown} alt="thumbs-down" />
                    </div>
                </div>
            </div>
            <div className="background-modal" onClick={handleClose}></div>
        </div>
    );
};
