import React, { FC } from 'react';

import SupportIcon from '../../../assets/contact-support-icon.svg';
import './ContactUsPopup.styles.scss';

type Props = {
    open: boolean;
    onClose: () => void;
};

export const ContactUsPopup: FC<Props> = (props) => {
    const { open, onClose } = props;

    const handleClose = () => {
        onClose();
    };

    if (!open) {
        return null;
    }

    return (
        <div className="contactUs-popup">
            <div className="contactUs-content">
                <div className="contactUs-header">
                    <img src={SupportIcon} alt="support-contact" />
                    <h1>Got more questions?</h1>
                </div>
                <p>
                    We’re here to help! Email us at{' '}
                    <span>support@studybuddy.gg</span> and we’ll get back to you
                    as soon as we can.
                </p>
            </div>
            <div className="background-modal" onClick={handleClose}></div>
        </div>
    );
};
