import React, { FC } from 'react';
import { getDayAfterTwoDays } from '~utils';

// import { useFeatureIsOn } from '@growthbook/growthbook-react';

import './Pricing.styles.scss';
import OfferIcon from '../../../assets/priceOffer-vector.svg';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from '~hooks';

import visa from '../../../assets/visa.svg';
import mastercard from '../../../assets/mastercard.svg';
import amex from '../../../assets/amex.svg';
import applepay from '../../../assets/applepay.svg';
import gpay from '../../../assets/gpay.svg';

import TenMQues from '../../../assets/10M_ques_answered.svg';

type PricingProps = {
    handleCTA: () => void;
};

export const Pricing: FC<PricingProps> = (props) => {
    const { handleCTA } = props;
    const { t } = useTranslation();
    const { isMobile } = useScreenSize();

    /* const onNewPricingClickHandler = () => {
        console.log('New Pricing Clicked');
        handleCTA('new_pricing');
    };

    const onPricingClickHandler = () => {
        console.log('Old Pricing Clicked');
        handleCTA('old_pricing');
    }; */

    const onPricingClickHandler = () => {
        handleCTA();
    };

    const pricingOfferEndDate = getDayAfterTwoDays();

    return (
        <div id="pricing-section">
            <div id="price">
                <h1 id="priceTitle">
                    StudyBuddy <span>{t('pricing')}</span>
                </h1>
                <h1 id="priceTitleMobile">
                    StudyBuddy <span>{t('pricing')}</span>
                </h1>
                {isMobile ? (
                    <div id="priceMobileContainer">
                        <div id="priceTag">
                            <p>{t('72_hour_access')}</p>
                        </div>
                        <div id="pricePrice">
                            {t('try_for')} <span>$0.99</span>
                        </div>
                        <div id="priceNoticeContainer">
                            <img
                                className="offerIcon"
                                src={OfferIcon}
                                alt="priceOffer"
                            />
                            <p id="priceMobileNotice">
                                {t('offer_valid_until')}{' '}
                                <span id="priceOffer">
                                    {pricingOfferEndDate}
                                </span>
                            </p>
                        </div>

                        <button
                            id="oldPricingCTA"
                            className="pricingCTA-shiny-button"
                            onClick={onPricingClickHandler}
                        >
                            {t('try_for')}
                            {' $0.99'}!
                        </button>
                        <div id="pricePoints">
                            <div className="pricePoint">
                                <img
                                    src="/assets/images/Checkbox.svg"
                                    alt="checkmark"
                                />
                                <p>
                                    {t('instant')}{' '}
                                    <span>{t('answers_&_explanations')}</span>
                                </p>
                            </div>
                            <div className="pricePoint">
                                <img
                                    src="/assets/images/Checkbox.svg"
                                    alt="checkmark"
                                />
                                <p>
                                    <span>{t('10+_million')}</span>{' '}
                                    {t('questions')} <span>{t('solved')}</span>
                                </p>
                            </div>
                            <div className="pricePoint">
                                <img
                                    src="/assets/images/Checkbox.svg"
                                    alt="checkmark"
                                />
                                <p>
                                    <span>{t('fully_undetectable')}</span>
                                </p>
                            </div>
                            <div className="pricePoint">
                                <img
                                    src="/assets/images/Checkbox.svg"
                                    alt="checkmark"
                                />
                                <p>
                                    <span>{t('plagiarism_free')}</span>
                                </p>
                            </div>
                            <div className="pricePoint">
                                <img
                                    src="/assets/images/Checkbox.svg"
                                    alt="checkmark"
                                />
                                <p>
                                    {t('powered_by')}{' '}
                                    <span>{t('cutting_edge_ai')}</span>
                                </p>
                            </div>
                        </div>
                        <hr className="hr-line"></hr>
                        <div id="priceBenefits">
                            {t('cancel_anytime')}
                            {'5.99'}
                            {t('per_week')}
                        </div>
                    </div>
                ) : (
                    <div id="priceDesktopContainer">
                        <div id="pricingDesktop">
                            <div id="priceTaglineDesktop">
                                <p>
                                    {t('72_unlimited')}{' '}
                                    <span>{t('access_studybuddy')}</span>
                                </p>
                            </div>
                            <div id="pricePrice">
                                {t('try_for')} <span>$0.99</span>
                            </div>
                            <div id="priceNoticeContainer">
                                <img
                                    className="offerIcon"
                                    src={OfferIcon}
                                    alt="priceOffer"
                                />
                                <p id="priceMobileNotice">
                                    {t('offer_valid_until')}{' '}
                                    <span id="priceOffer">
                                        {pricingOfferEndDate}
                                    </span>
                                </p>
                            </div>

                            <button
                                id="oldPricingCTA"
                                className="pricingCTA-shiny-button"
                                onClick={onPricingClickHandler}
                            >
                                {t('get_started')}
                            </button>
                            <div id="pricePoints">
                                <div className="pricePoint">
                                    <img
                                        src="/assets/images/Checkbox.svg"
                                        alt="checkmark"
                                    />
                                    <p>
                                        {t('instant')}{' '}
                                        <span>{t('answers')}</span>
                                    </p>
                                </div>
                                <div className="pricePoint">
                                    <img
                                        src="/assets/images/Checkbox.svg"
                                        alt="checkmark"
                                    />
                                    <p>
                                        <span>{t('fully_undetectable')}</span>
                                    </p>
                                </div>
                                <div className="pricePoint">
                                    <img
                                        src="/assets/images/Checkbox.svg"
                                        alt="checkmark"
                                    />
                                    <p>
                                        <span>{t('10+_million')}</span>{' '}
                                        {t('questions')}{' '}
                                        <span>{t('solved')}</span>
                                    </p>
                                </div>

                                <div className="pricePoint">
                                    <img
                                        src="/assets/images/Checkbox.svg"
                                        alt="checkmark"
                                    />
                                    <p>
                                        <span>{t('plagiarism_free')}</span>
                                    </p>
                                </div>
                                <div className="pricePoint">
                                    <img
                                        src="/assets/images/Checkbox.svg"
                                        alt="checkmark"
                                    />
                                    <p>
                                        {t('powered_by')}{' '}
                                        <span>{t('cutting_edge_ai')}</span>
                                    </p>
                                </div>
                                <div className="pricePoint">
                                    <img
                                        src="/assets/images/Checkbox.svg"
                                        alt="checkmark"
                                    />
                                    <p>
                                        <span>{t('530K+')}</span>{' '}
                                        {t('students')}
                                    </p>
                                </div>
                            </div>
                            <div id="priceBenefits">
                                {t('cancel_anytime_2')}
                                {'5.99'}
                                {t('per_week')}
                            </div>
                        </div>
                        <hr className="hr-line"></hr>
                        <div id="paymentMethodContainer">
                            <div className="cardsContainer">
                                <img src={visa} alt="visa" />
                                <img src={mastercard} alt="ms" />
                                <img src={amex} alt="amex" />
                                <img src={gpay} alt="gpay" />
                                <img src={applepay} alt="applepay" />
                            </div>
                            <img src={TenMQues} alt="TenMQues" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
