import React from 'react';

import type { InputProps } from './Input.types';
import type { FC } from 'react';
import { computeInputFieldStyles } from './Input.utils';

import './Input.styles.scss';

export const InputField: FC<InputProps> = (props) => {
    const {
        id,
        label,
        type,
        name,
        value,
        onChange,
        error,
        placeholder,
        required,
        style,
        ...rest
    } = props;

    const { inputLabelStyles, inputStyles } = computeInputFieldStyles(style);

    return (
        <div className="input-field">
            {label && (
                <label htmlFor={label} style={inputLabelStyles}>
                    {label}
                    {/* <span>*</span> */}
                </label>
            )}
            {
                <input
                    type={type}
                    placeholder={placeholder}
                    name={name}
                    onChange={onChange}
                    value={value}
                    style={inputStyles}
                    required={required}
                    {...rest}
                />
            }
            {error && <p className="error">{error}</p>}
        </div>
    );
};
