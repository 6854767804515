import React from 'react';

import { useTranslation } from 'react-i18next';

import './Numbers.styles.scss';

export const Numbers = () => {
    const { t } = useTranslation();

    return (
        <div id="numbers">
            <div id="numbersContainer">
                <div id="factPoint">
                    <h3>{t('10M+')}</h3>
                    <h5>{t('solutions')}</h5>
                </div>
                <div id="factPoint">
                    <h3>{t('530K+')}</h3>
                    <h5>{t('students')}</h5>
                </div>
                <div id="factPoint">
                    <h3>{t('98%')}</h3>
                    <h5>{t('accurate')}</h5>
                </div>
            </div>
        </div>
    );
};
