import React, { useRef, useEffect } from 'react';
import CanvasConfetti from 'react-canvas-confetti';
import './Confetti.styles.scss';
const Confetti = () => {
    const animationInstance = useRef<any>(null);

    const makeShot = (particleRatio: number, opts: object) => {
        animationInstance.current && animationInstance.current({
            ...opts,
            origin: { x: Math.random(), y: 0 }, // Setting the y origin to 0 makes it start from the top.
            particleCount: Math.floor(200 * particleRatio),
        });
    };

    const fire = () => {
        makeShot(0.25, {
            spread: 26,
            startVelocity: 55,
        });

        makeShot(0.2, {
            spread: 60,
        });

        makeShot(0.9, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8,
        });

        makeShot(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2,
        });

        makeShot(0.6, {
            spread: 120,
            startVelocity: 45,
        });
    };

    useEffect(() => {
        fire();
        // Delay for 1 second then fire again
        setTimeout(() => {
            fire();
        }, 1000);
    }, []);

    const getInstance = (instance: any) => {
        animationInstance.current = instance;
    };

    return (
        <>
            <CanvasConfetti
                refConfetti={getInstance}
                className="confetti"
            />
        </>
    );
};

export default Confetti;
